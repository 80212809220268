<template lang="pug">
.content.items-baseline
  add-photo-modal(v-if="currentModal === 'AddPhotoModal'" @closemodal="currentModal = ''" @newimage="newImage")
  .container-fluid
    .w-full.h-full
      .flex.flex-col.items-start.font-inter.text-gray-400.font-medium.p-8
        .flex.h-fit.w-full.flex-col.items-start
          h1.title Perfil e segurança
          p.font-titillium.font-semibold.text-2xl.text-gray-600 Dados do perfil
          .subtitle-info.mt-4 Gerencie e mantenha atualizado os seus dados de perfil.
        .flex.flex-col.justify-center.gap-4.mb-4(
          class="w-1/2"
        )
          .h-40.w-40
            span.firstLetter.top-user-icon(
              v-if="failedImg"
              ) {{ user.username[0] }}
            img.aspect-square.object-cover.rounded-md.cursor-pointer.transition(
              v-else-if="!failedImg"
              class="hover:brightness-90 active:scale-95"
              @click="showUploadImageModal = true"
              ref="img"
              :src="`${schema}://${cservice}/avatar/${user.id}.png?version=${userAvatarCache}`" @error="\
              failedImg = true;\
              $forceUpdate();\
            ")
          v-button-secondary(
            role="button" 
            @click="showUploadImageModal = true"
          ) Adicionar ou alterar foto
          upload-image(v-if="showUploadImageModal", title="Alterar foto de perfil", @close="showUploadImageModal = false", @apply-image="handleApplyImage")
        div(
          class="w-1/2"
        )
          simple-input.mt-4(
            label="Nome"
            v-model="usernameModel"
            )
          simple-input.mt-4(
            label="Email"
            v-model="user.email"
            )
          simple-input.mt-4(
            label="Cargo"
            v-model="occupationModel"
            )
          .flex.justify-end.mt-4
            v-button(
              @click="updateProfile"
              ) Salvar
        p.font-titillium.font-semibold.text-2xl.text-gray-600.mt-14 Segurança
        .flex.flex-col.items-start.font-inter.text-gray-400.font-medium Para sua segurança, você pode alterar sua senha de acesso sempre que necessário.
        div(
          class="w-1/2"
        )
          simple-input.mt-4(
            label="Senha atual"
            v-model="password"
            :type="'password'"
            )
          simple-input.mt-4(
            label="Nova senha"
            v-model="newPassword"
            :type="'password'"
            )
          .flex.flex-col.mt-2.gap-2.items-start(v-if="passwordRequirements.length")          
            .font-inter.text-gray-400.font-bold.text-xs.text-red-500 A nova senha deve:
            ul.pl-2
              li.text-red-500(
                v-for="requirement in passwordRequirements"
                :key="requirement"             
              ) 
                .flex.gap-2.items-center.justify-start.text-xs
                  span.material-icons.notranslate.text-red-500 *
                  span {{ requirement }}
          simple-input.mt-4(
            label="Confirme nova senha"
            v-model="confirmNewPassword"
            :type="'password'"
            )
          .flex.justify-end.mt-4
            v-button(
              @click="changePassword"
              ) Salvar
        p.font-titillium.font-semibold.text-2xl.text-gray-600.mt-14 Dupla autenticação
        .flex.flex-col.items-start.font-inter.text-gray-400.font-medium(
          class="w-1/2"
          ) Toda vez que você entrar em sua conta será necessário inserir um código de segurança.
          .flex.flex-col.items-start.mt-4
            p.font-semibold.text-black E-mail
            p Receba o código de autenticação via e-mail
            .flex.items-center.gap-2.bg-white.p-2.rounded-md.w-fit.px-4.mt-2
              p.text-black Ativar 
              switch-checkbox.mt-2(
                v-model="user.two_auth_mail"
              )
          .flex.flex-col.items-start.mt-4
            p.font-semibold.text-black Aplicativo
            p Receba o código de autenticação via aplicativo
            .flex.items-center.gap-2.bg-white.p-2.rounded-md.w-fit.px-4.mt-2
              p.text-black Ativar 
              switch-checkbox.mt-2(
                v-model="user.two_auth_otp"
              )
            modal(
              v-if="user.two_auth_otp && !otpTokenValidated"
              @click="user.two_auth_otp = !user.two_auth_otp"
            )
              modal-body-no-footer(class="!max-w-3xl")
                template(v-slot:header)
                  .flex.items-center.gap-6.justify-between
                    h4.text-2xl.text-gray-500.font-semibold.font-inter Configurar dupla autenticação
                    .material-icons.notranslate.cursor-pointer(
                      @click="user.two_auth_otp = !user.two_auth_otp"
                    ) close
                template(v-slot:body)
                  .flex.gap-4.h-fit.w-fit.flex-col.items-start
                    p.font-semibold 
                      | Primeiro passo: 
                      span.font-medium
                        | Abra o aplicativo desejado e selecione a opção de QR Code, depois leia o QR Code ao lado, caso não consiga ler o QR Code é possível utilizar o seguinte link 
                        span.text-blue-800.cursor-pointer.font-semibold(
                          @click="copyQrCode"
                        )
                          | Copiar link
                    .flex.w-full.gap-8
                      img.w-40(
                        src="@/assets/images/twoOtp1.png"
                      )
                      img.h-60.w-60.rounded-md(
                        :src="qrCodeImage"
                      )
                    p.font-semibold 
                      | Segundo passo: 
                      span.font-medium
                        | Copie o código gerado pelo aplicativo e cole no campo de texto ao lado e aperte em validar
                    .flex.w-full.gap-8
                      img.w-40(
                        src="@/assets/images/twoOtp2.png"
                      )
                      .flex.w-60.items-center.justify-center.flex-col.gap-4
                        simple-input.mt-4(
                            label="Token"
                            v-model="otpToken"
                          )
                        v-button(
                          class="!min-w-full"
                          @click="validateOtpToken"
                        ) Validar token
                    p.font-semibold 
                      | Observações: 
                      span.font-medium
                        | O aplicativo usado no exemplo é a extensão "Autenticador" do google, porém é possível utilizar diversos outros aplicativos.
        p.font-titillium.font-semibold.text-2xl.text-gray-600.mt-14 Token
        .flex.flex-col.items-start.font-inter.text-gray-400.font-medium Secção para inclusão do token de integração.
        div(
          class="w-1/2"
          v-if="tokenId"
        )
          label.up-placeholder(
            name="password"
            ) Token
          .flex.items-center.justify-between
            p {{ tokenId }}
            .flex.gap-2.cursor-pointer(
              @click="copyClipboard"
              )
              span.material-icons.notranslate.copy-icon &#xe157
              span.font-semibold.text-black Copiar
          .w-full.flex.justify-end
            v-button-secondary.mr-auto.mt-4(
              @click="removerToken"
              ) Remover
        div(
          class="w-1/2"
          v-else
        )
          label.up-placeholder Não há nenhum token gerado, clique no botão gerar.
          .w-full.flex.justify-end
            v-button(
              @click="generateToken"
              ) Gerar

        span.w-full(
          v-if="(user.profile === 'p_admin' || user.profile === 'p_manager') && user.type !== 'opens'"
        )
          p.font-titillium.font-semibold.text-2xl.text-gray-600.mt-14 Ambiente
          .flex.flex-col.items-start.font-inter.text-gray-400.font-medium(
            class="w-1/2"
            ) Selecione o painel inicial que deseja utilizar após o login.
            .flex.flex-col.items-start.mt-4
              .flex.items-center.gap-2.bg-white.p-2.rounded-md.w-fit.px-4
                p.text-black Ambiente de Comunicação 
                switch-checkbox.mt-2(
                  v-model="defaultEnvironment.client"
                )
              .flex.items-center.gap-2.bg-white.p-2.rounded-md.w-fit.px-4
                p.text-black Ambiente de Gestão  
                switch-checkbox.mt-2(           
                  v-model="defaultEnvironment.manager"
                )

</template>
<script>
import AddPhotoModal from '../Preferences/AddAvatar/UploadImage.vue';
import Timezones from './ProfileSecurity.Timezones.vue';
import ProgressModal from '@/components/Modals/ProgressModal.vue';
import SuccessModal from '@/components/Modals/SuccessModal.vue';
import FailedModal from '@/components/Modals/FailedModal.vue';
import authService from '@/services/auth.js';
import VButton from '@/components/Buttons/v-button.vue';
import SimpleInput from '@/components/Inputs/SimpleInput.vue';
import SwitchCheckbox from '@/components/Inputs/SwitchCheckbox.vue';
import QRCode from '../../../node_modules/qrcode';
import { twoAuthLink, otpValidateToken, updateEmailTwoFactor, updateOtpTwoFactor } from '@/services/cservice.js';
import Modal from '@/components/Modals/Modal.vue';
import ModalBodyNoFooter from '@/components/Modals/ModalBodyNoFooter.vue';
import VButtonSecondary from '@/components/Buttons/v-button-secondary.vue';
import UploadImage from '@/components/Modals/UploadImage.vue';

export default {
  title: 'Opens - Perfil e segurança',
  components: {
    AddPhotoModal,
    Timezones,
    ProgressModal,
    SuccessModal,
    FailedModal,
    VButton,
    SimpleInput,
    SwitchCheckbox,
    Modal,
    ModalBodyNoFooter,
    UploadImage,
    VButtonSecondary,
  },
  data() {
    return {
      showUploadImageModal: false,
      otpToken: '',
      qrCodeUrl: '',
      isFirstTime: true,
      otpTokenValidated: false,
      userAvatarCache: 0,
      qrCodeImage: null,
      password: '',
      passwordToken: '',
      newPassword: '',
      confirmNewPassword: '',
      currentModal: '',
      tokenId: '',
      timezone: {},
      usernameModel: '',
      occupationModel: '',
      failedImg: false,
      schema: process.env.VUE_APP_SCHEMA,
      cservice: process.env.VUE_APP_CSERVICE,
      auth: process.env.VUE_APP_AUTH,
      defaultEnvironment: {
        client: false,
        manager: false,
      },
      strongPasswordOptions: null,
      passwordRequirements: [],
      passwordStrengthDictionary: {
        uppercase: 'utilizar letras maiúsculas',
        lowercase: 'utilizar letras minúsculas',
        number: 'conter números',
        symbol: 'possuir caracteres especiais',
      },
      timeoutToValidatePasswordStrength: null,
    };
  },
  watch: {
    'user.two_auth_mail': {
      handler(prevVal, nextVal) {
        if(prevVal === nextVal) return;
        this.updateEmailAuth();
      },
      immediate: false,
    },
    'user.two_auth_otp': {
      handler(prevVal, nextVal) {
        if(prevVal == nextVal) return;
        this.updateOtpAuth();
      },
      immediate: false,
    },
    user(value) {
      this.userAvatarCache++;
    },
    context: function () {
      this.newImage(this.context);
    },
    isClientDefaultEnvironment(val) {
      if (val) {
        this.defaultEnvironment.manager = false;
        return localStorage.setItem('@yunique:default-environment', 'client');
      }
      if (!val && !this.isManagerDefaultEnvironment) localStorage.removeItem('@yunique:default-environment');
    },
    isManagerDefaultEnvironment(val) {
      if (val) {
        this.defaultEnvironment.client = false;
        return localStorage.setItem('@yunique:default-environment', 'manager');
      }
      if (!val && !this.isClientDefaultEnvironment) localStorage.removeItem('@yunique:default-environment');
    },
    newPassword() {
      this.debouncedValidatePasswordStrength();
    },
  },
  computed: {
    context() {
      return this.$store.getters['modal/getContext'];
    },
    userOtp() {
      return this.user.two_auth_mail;
    },
    isClientDefaultEnvironment() {
      return this.defaultEnvironment.client;
    },
    isManagerDefaultEnvironment() {
      return this.defaultEnvironment.manager;
    },
  },
  methods: {
    async handleApplyImage(data) {
      try {
        let formData = new FormData();
        formData.append('avatar', data.blobImage);
        await this.$http
          .put(`${this.requestLinks.cservice}/companies/users/avatar/${this.user.id}`, formData)
          .then(() => {
            this.$store.commit('modal/setContext', URL.createObjectURL(data.blobImage));
            this.$store.dispatch('user/requestUser', this.$http);
          });
        this.$toast.success('Imagem do avatar alterada com sucesso');
      } catch (error) {
        console.error(error);
        this.$toast.error('Ocorreu um erro ao alterar o avatar');
      }
      this.showUploadImageModal = false;
    },
    async generateQRCode() {
      const res = await twoAuthLink(this.user.id);
      this.qrCodeUrl = res.url;
      QRCode.toDataURL(this.qrCodeUrl).then((url) => {
        this.qrCodeImage = url;
      });
    },
    copyQrCode() {
      navigator.clipboard.writeText(this.qrCodeUrl);
      this.$toast.success('Link copiado');
    },
    async validateOtpToken() {
      const res = await otpValidateToken(this.user.id, this.otpToken);
      if (res.validate == false) return this.$toast.error('O token inserido é inválido!');
      this.otpTokenValidated = true;
      this.$toast.success('Alteração feito com sucesso');
    },
    async updateEmailAuth() {
      try {
        await updateEmailTwoFactor(this.user.id, this.user.two_auth_mail);
        this.$toast.success('Alteração feito com sucesso');
      } catch (error) {
        this.$toast.error('Não foi possível salvar');
      }
    },
    async updateOtpAuth() {
      if (this.isFirstTime == false && !this.otpTokenValidated) return;
      try {
        if (this.user.two_auth_otp == false) {
          this.otpTokenValidated = false;
          await updateOtpTwoFactor(this.user.id, this.user.two_auth_otp);
          this.$toast.success('Alteração feito com sucesso');
        } else {
          await this.generateQRCode();
        }
      } catch (error) {
        this.$toast.error('Não foi possível salvar');
      }
      this.isFirstTime = !this.isFirstTime;
    },
    copyClipboard() {
      if (this.tokenId) {
        navigator.clipboard.writeText(this.tokenId);
        this.$toast.open({ type: 'info', message: 'Token copiado' });
      }
    },
    async generateToken() {
      try {
        const data = await authService.generateToken();
        this.tokenId = data;
        this.$toast.success('Token criado com sucesso!');
      } catch (error) {
        this.$toast.error('Falha ao criar token');
      }
    },
    async removerToken() {
      try {
        await authService.removerToken({ tokenId: this.tokenId });
        this.tokenId = '';
        this.token = '';
        this.passwordToken = '';
        this.$toast.success('Token removido com sucesso!');
      } catch (e) {
        this.$toast.error('Falha ao remover token');
      }
    },
    async updateProfile() {
      try {
        const r = await this.$http.put(`${this.schema}://${this.cservice}/companies/users/${this.user.id}`, {
          username: this.usernameModel,
          occupation: this.occupationModel,
          timezone: this.timezone.tzCode,
          utc: this.timezone.utc,
          email: this.user.email,
        });
        this.$store.commit('user/SET_USER', r.data.user);
        await this.$store.dispatch('user/requestUser', this.$http);
        this.$toast.success('Suas alteraçoes foram salvas com sucesso!');
      } catch (e) {
        if (JSON.parse(e.request.response).error.startsWith('E-mail'))
          return this.$toast.error('Esse email ja está cadastrado! Use outro por favor.');
        this.$toast.error('Falha ao salvar alterações, tente novamente mais tarde!');
      }
    },
    setTimezone(payload) {
      this.timezone = payload;
    },
    newImage(payload) {
      this.$refs['img'].src = payload;
    },
    async getStrongPasswordOptions() {
      if (this.strongPasswordOptions) return this.strongPasswordOptions;
      try {
        const baseUrl = `${this.schema}://${this.cservice}`;
        const { data: passwordOptions } = await this.$http.get(`${baseUrl}/companies/configs/strong-password-options`);

        this.strongPasswordOptions = passwordOptions;
        return passwordOptions;
      } catch (error) {
        console.error(error);
        return null
      }
    },
    debouncedValidatePasswordStrength() {
      if(this.debouncedValidatePasswordStrength) clearTimeout(this.timeoutToValidatePasswordStrength);
      this.timeoutToValidatePasswordStrength = setTimeout(this.validatePasswordStrength, 1000);
    },
    async validatePasswordStrength() {
      const validations = {
        lowercase: /[a-z]/,
        uppercase: /[A-Z]/,
        number: /\d/,
        symbol: /[^a-zA-Z0-9]/,
      };

      const passwordOptions = await this.getStrongPasswordOptions();

      if (!passwordOptions?.value_json) return [];
      const companyPasswordOptions = passwordOptions?.value_json;

      const passwordRequirements = [];
      if (companyPasswordOptions.minChars > this.newPassword.length)
        passwordRequirements.push(`conter ${companyPasswordOptions.minChars} caracteres ou mais`);

      for (const [key, regex] of Object.entries(validations)) {
        const isRequired = companyPasswordOptions[key];
        const passwordContainsKey = regex.test(this.newPassword);

        if (isRequired && !passwordContainsKey)
          passwordRequirements.push(this.passwordStrengthDictionary[key]);
      }

      this.passwordRequirements = passwordRequirements;
      return passwordRequirements;
    },
    async changePassword() {
      this.passwordRequirements = []
      if (this.newPassword !== this.confirmNewPassword) {
        this.$toast.open({
          type: 'info',
          message: 'Nova senha não foi apropriadamente confirmada',
        });
        return;
      }

      const passwordRequirements = await this.validatePasswordStrength();
      if (passwordRequirements.length)
        return this.$toast.error('A senha deve atender a alguns requisitos para ser considerada forte.');

      try {
        const r = await this.$http.put(`${this.schema}://${this.cservice}/companies/users/password`, {
          password: this.password, // old password
          new_password: this.newPassword,
        });
        this.$log.info(r.data);
        this.$toast.success('Alterações foram salvas com sucesso!');
      } catch (e) {
        this.$toast.error('Falha ao salvar alterações, tente novamente mais tarde.');
      }
    },
  },
  async created() {
    this.usernameModel = this.user.username;
    this.occupationModel = this.user.occupation;
    if (this.user.two_auth_otp == false) {
      await this.generateQRCode();
    } else {
      this.otpTokenValidated = true;
    }
    try {
      const { tokenId } = await authService.findTokenByUserId({
        userId: this.user.id,
        companyId: this.user.company_id,
      });
      this.tokenId = tokenId;
    } catch (error) {
      this.$toast.open({
        type: 'info',
        message: 'Não foi possivel recuperar um token de acesso',
      });
    }

    const selectedEnvironment = localStorage.getItem('@yunique:default-environment') || '';
    this.defaultEnvironment.client = selectedEnvironment === 'client';
    this.defaultEnvironment.manager = selectedEnvironment === 'manager';
  },
};
</script>
<style>
.top-user-icon {
  font-size: 80px !important;
  color: var(--secondary-color) !important;
}
</style>
