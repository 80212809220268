<template lang="pug">
.flex.justify-between.w-full.items-center.cursor-move(
  :class="{ 'cursor-move': isDragging }",
  @mousedown="startDrag",
  ref="dialerHeader"
)
  .flex.gap-2.items-center.justify-center
    .material-icons.notranslate.text-lg.cursor-move.transition-all(
      class="hover:opacity-50"
      v-tooltip="'Mover dialer'"
    ) control_camera
    p.font-plus.text-white
      slot
    .w-1.h-1.rounded-full.bg-gray-500
    p.font-plus.text-gray-500.text-xs SIP {{ user.endpoint }}
  .flex.gap-1
    f-tooltip
      .material-icons.notranslate.text-lg.cursor-pointer.transition-all(
        class="hover:opacity-50"
      ) info
      template(#popper)
        device-status-info
    .material-icons.notranslate.text-lg.cursor-pointer.transition-all(
      class="hover:opacity-50"
      v-tooltip="'Minimizar dialer'"
      @click="handleClose"
    ) remove
</template>
<script>
import DeviceStatusInfo from '@/components/Dialer/dialer-device-status-info.vue';
import { Tooltip } from 'floating-vue';

export default {
  components: {
    FTooltip: Tooltip,
    DeviceStatusInfo,
  },
  computed: {
    isDragging() {
      return this.$store.getters['sip/isDragging'];
    },
    offset() {
      return this.$store.getters['sip/offset'];
    },
    position() {
      return this.$store.getters['sip/position'];
    },
  },
  methods: {
    handleClose() {
      this.$store.commit('dialer/setIsDialerOpen', false);
    },
    startDrag(event) {
      this.$store.commit('sip/setIsDragging', true);
      this.$store.commit('sip/setOffset', {
        x: event.clientX - this.position.x,
        y: event.clientY - this.position.y,
      });
      document.addEventListener('mousemove', this.onDrag);
      document.addEventListener('mouseup', this.stopDrag);
    },
    onDrag(event) {
      if (this.isDragging) {
        const element = this.$refs.dialerHeader;
        const elementHeight = element.offsetHeight + 100;

        let newX = event.clientX - this.offset.x;
        let newY = event.clientY - this.offset.y;

        const windowHeight = window.innerHeight;

        newY = Math.max(0, Math.min(newY, windowHeight - elementHeight));

        this.$store.commit('sip/setPosition', {
          x: newX,
          y: newY,
        });
      }
    },
    stopDrag() {
      this.$store.commit('sip/setIsDragging', false);
      document.removeEventListener('mousemove', this.onDrag);
      document.removeEventListener('mouseup', this.stopDrag);
    },
  },
  onUnmounted() {
    document.removeEventListener('mousemove', this.onDrag);
    document.removeEventListener('mouseup', this.stopDrag);
  },
};
</script>
