<template lang="pug">
button.flex.gap-2.items-center.justify-center.w-fit.font-plus.transition-all.font-bold.truncate.cursor-pointer.relative(
  @click="$emit('click')"
  class="dark:invert"
  :class="[size, type, isGhost, isRounded, textSize, disabledStatusClass]"
  :disabled="loading || disabled"
)
  span(:class="[textColor]" v-if='hasSlot("default")')
    slot
  slot(name='icon' v-if='!loading && hasSlot("icon")')
  app-icon.animate-spin.flex.items-center.justify-center(
    v-if='loading'
    :small='small'
    :large='large'
    outlined,
    class="!text-white"
  ) progress_activity
</template>

<script>
import AppIcon from '@/components/Icons/app-icon.vue';

export default {
  components: {
    AppIcon,
  },
  props: {
    large: Boolean,
    small: Boolean,
    warning: Boolean,
    danger: Boolean,
    alert: Boolean,
    success: Boolean,
    light: Boolean,
    dark: Boolean,
    ghost: Boolean,
    rounded: Boolean,
    loading: Boolean,
    disabled: Boolean,
    link: Boolean,
  },
  computed: {
    textSize() {
      if (this.small) return 'text-xs';
      if (this.large) return 'text-base';
      return 'text-sm';
    },
    size() {
      if (this.link) return '';
      if (this.small) return '!h-8 px-2';
      if (this.large) return '!h-12 px-4';
      return '!h-10 px-3';
    },
    type() {
      if (this.link) return 'bg-transparent underline hover:opacity-40';
      if (this.warning) return 'bg-amber-500';
      if (this.danger || this.alert) return 'bg-red-500';
      if (this.success) return 'bg-green-400';
      return 'bg-brand-400';
    },
    textColor() {
      if (this.link) return '!text-brand-600';
      if (this.dark) return '!text-neutral-800 !dark:text-white';
      if (this.light) return '!text-white !dark:text-neutral-800';
      return '!text-white !dark:text-neutral-800';
    },
    isGhost() {
      if (this.ghost) return '!bg-transparent';
      return '';
    },
    isRounded() {
      if (this.link) return '';
      if (this.small && this.rounded) return '!rounded-full';
      if (this.large && this.rounded) return '!rounded-full';
      if (this.rounded) return '!rounded-full';
      return '!rounded-md';
    },
    disabledStatusClass() {
      if (this.loading || this.disabled) return 'cursor-wait !opacity-60';
      return 'hover:brightness-90 active:scale-95';
    },
  },
  methods: {
    hasSlot(name) {
      return !!this.$slots[name];
    },
  },
};
</script>
