<template lang="pug">
.call-received(class="dark:bg-gray-100")#callingId
  a.call-received-title(style="color: #adb5bd !important") Chamando:
  .call-profile-img-box
    .colega-card-aligner
      .colega-status-disabled.no-margin(v-if="identityContact?.state" style="top: 0%" :class="(data ? 'status-red' : translateCServiceStatus[identityContact?.state])")
    .call-profile-img-icon
      .w-14.h-14.bg-brand-500.bg-opacity-50.rounded.flex.justify-center.items-center.border.border-brand-100(class="dark:invert")
        contact-avatar(
          :identity-contact="identityContact"
          :failed-img="failedImg"
          @failedImg="failedImg = true"
        )

  .call-text-box
    p.calling-dial-name.white-text.text-marker.truncate(class="max-w-[14rem]" v-tooltip="identityContact?.name") {{ identityContact?.name }}
  .client-text(v-if="identityContact?.tag" style="padding: 5px !important; width: max-content !important; margin-bottom: 2px; margin-top: 0px;") {{ identityContact?.tag }}

  .client-text.Colega.colega-tag.p-1(v-else-if="identityContact?.state") Colega

  a.card-info-text(style="color: #adb5bd !important").text-marker {{ identityContact?.organization }}
  a.card-info-text(style="color: #adb5bd !important").text-marker {{ identityContact?.number || toNumber }}
  .call-line-separator(class="dark:bg-gray-300")
  .icons-aligner(style="width: 100%; height: 30%; align-items: none")
    .left-icon(style="width: 50%")
      div(style="display: flex; align-items: center; height: 90px")
        span.accept-call-icon.material-icons.notranslate(style="display: flex;  animation: none;  border: 1px dashed var(--green-default);  background: transparent;  width: 72px;  height: 72px;  font-size: 40px !important;") phone
      a.card-info-text(style="color: #adb5bd !important; width: 100px").text-marker Ligando através do 
        a(style="font-weight: bold") Ramal {{ user.endpoint }}

    .right-icon(@click="hangUpCall" v-if="isWebRTC" style="width: 50%" class="right-icon")
      div(style="display: flex; align-items: center; height: 90px" class="hover:opacity-50 transition-all active:scale-90")
        .turnoff-call-icon
          span.material-icons.notranslate(style="font-size: 28px") phone
      a.card-info-text(style="color: #adb5bd !important").text-marker Desligar
</template>

<script>
import SipMixin from '@/mixins/invite-calls.js';
import ContactAvatar from './ContactAvatar.vue';
import { getUserRingingSound } from '@/services/cservice';
import UserOrContactMixin from '@/mixins/user-or-contact.mixin';

export default {
  mixins: [UserOrContactMixin, SipMixin],
  components: {
    ContactAvatar,
  },
  data() {
    return {
      translateCServiceStatus: {
        enabled: 'Disponível',
        disabled: 'NaoPerturbe',
        online: 'Disponível',
        auto: 'Disponível',
      },
      failedImg: false,
    };
  },
  computed: {
    callData() {
      return this.$store.getters['cstate/GET_CALL_PAYLOAD'];
    },
    toNumber() {
      return this.data?.to || this.callData?.to;
    },
    toId() {
      return this.data?.toId || this.callData?.toId;
    },
  },

  watch: {
    callData(value) {
      if (value) {
        this.setContactOrOperator({
          id: this.toId,
          number: this.toNumber,
        });
      }
    },
  },
  methods: {
    async hangUpCall() {
      try {
        await this._hangUpCall();
      } catch (e) {
        if (e.response.status == 401) {
          window.location.pathname = '/auth/login';
        }
      } finally {
        this.$emit('hangup-call');
      }
    },
    playAudio() {
      if (!this.isWebRTC) return;
      const volume = this.$store.getters['userPreferences/getAudioVolume'];
      const audio = this.$store.getters['cstate/ringingSound'];
      audio.volume = volume;
      audio.play();
    },
  },
  mounted() {
    try {
      const localStorageUserRingingSound = localStorage.getItem(`@yunique:ringing-sound:${this.user.id}`);
      if (localStorageUserRingingSound) {
        const ringingSounds = this.$store.getters['cstate/ringingSounds'];
        const selectedRingingSound = ringingSounds[localStorageUserRingingSound] || ringingSounds['default'];

        this.$store.commit('cstate/setRingingSound', selectedRingingSound);
      } else {
        getUserRingingSound(this.user.id).then((data) => {
          if (data.ringing_sound) {
            const ringingSounds = this.$store.getters['cstate/ringingSounds'];
            const selectedRingingSound = ringingSounds[data.ringing_sound] || ringingSounds['default'];

            this.$store.commit('cstate/setRingingSound', selectedRingingSound);
            localStorage.setItem(`@yunique:ringing-sound:${this.user.id}`, data.ringing_sound);
          }
        });
      }
    } catch (e) {}
    this.playAudio();
    const audio = this.$store.getters['cstate/ringingSound'];
    audio.addEventListener('ended', this.playAudio);
  },
  beforeDestroy() {
    const audio = this.$store.getters['cstate/ringingSound'];
    audio.removeEventListener('ended', this.playAudio);
    audio.pause();
    audio.currentTime = 0;
  },
  destroyed() {
    const audio = this.$store.getters['cstate/ringingSound'];
    audio.removeEventListener('ended', this.playAudio);
    audio.pause();
    audio.currentTime = 0;
  },
  async created() {
    await this.setContactOrOperator({
      id: this.toId,
      number: this.toNumber,
    });
  },
};
</script>

<style>
@keyframes pulse-red {
  0% {
    transform: scale(0.98);
    box-shadow: 0 0 0 0 rgba(var(--red-default), 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(220, 96, 88, 0);
  }

  100% {
    transform: scale(0.98);
    box-shadow: 0 0 0 0 rgba(220, 96, 88, 0);
  }
}
.turnoff-call-icon {
  cursor: pointer;
  font-size: 34px !important;
  padding: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  color: #fff;
  background: var(--red-default);
  width: 56px;
  height: 56px;
  border-radius: 50%;
  transform: scale(1);
  animation: pulse-red 2s ease infinite;
}
.send-csat-icon {
  cursor: pointer;
  font-size: 34px !important;
  padding: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  color: var(--red-default);
  background: #212529;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  transform: scale(1);
  animation: pulse-red 2s ease infinite;
}

.call-received-note {
  margin: 5px;
  font-size: 0.75rem;
  color: rgb(184, 184, 184);
}
</style>
