import $http from './client';
const service = `${process.env.VUE_APP_SCHEMA}://${process.env.VUE_APP_CSERVICE}`;

async function getDeviceConfig(payload) {
  try {
    const response = await $http.get(`${service}/snep/users/peer/${payload}`);
    return response.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function logout() {
  try {
    await $http.get(`${service}/companies/users/logout`);
  } catch (e) {
    return Promise.reject(e);
  }
}

async function getCompanyPauses() {
  try {
    const { data } = await $http.get(`${service}/companies/pauses/type/public`);
    return data.pauses;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function getUserPause(userId) {
  try {
    const { data } = await $http.get(`${service}/companies/availability-records/users/${userId}/pause`);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function getQueue(id) {
  try {
    const { data } = await $http.get(`${service}/companies/bonds/queue/${id}`);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function getUserBond(id) {
  try {
    const { data } = await $http.get(`${service}/companies/bonds/users/${id}`);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function addUserQueueBond(userId, queue, permission) {
  try {
    const { data } = await $http.post(`${service}/companies/bonds/users/${userId}`, {
      queue,
      type: 'queue',
      permission,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function getAllUsersBonds(params) {
  try {
    const { data } = await $http.get(`${service}/companies/bonds/company/users`, { params });
    return data;
  } catch (e) {
    throw new Error('Erro ao buscar todos os usuários vinculados.');
  }
}

async function getBondsWorkGroupById(workGroupId) {
  try {
    const { data } = await $http.get(`${service}/companies/bonds/work-groups/${workGroupId}`);
    return data;
  } catch (e) {
    throw new Error('Erro ao buscar grupos de trabalho vinculados.');
  }
}

async function getUserActions($http, { user_id }) {
  try {
    const response = await $http.get(`${service}/action/user/${user_id}`);
    return response.data || {};
  } catch (e) {
    return Promise.reject(e);
  }
}

async function changeTransport({ peer, transport }) {
  try {
    await $http.put(`${service}/snep/users/peer/transport`, {
      peer,
      transport,
    });
  } catch (error) {
    return Promise.reject(e);
  }
}

export async function getUserById(userId) {
  try {
    const { data } = await $http.get(`${service}/companies/users/search/${userId}`);
    return data.user;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getCompanyById(companyId) {
  const { data } = await $http.get(`${service}/companies/search/${companyId}`);
  return data?.company;
}

async function getUserByPeer({ peer, company_id }) {
  try {
    const { data } = await $http.get(`${service}/companies/users/devices/search`, {
      params: {
        peer,
        company_id,
      },
    });
    return data;
  } catch (error) {
    return {};
  }
}

export async function getContactFromCservice({ peer, company_id }) {
  try {
    const { data } = await $http.post(`${service}/companies/users/devices/search`, {
      peer,
      company_id,
    });
    return data;
  } catch (error) {
    return {};
  }
}

async function getCompanyQueues() {
  try {
    const { data } = await $http.get(`${service}/companies/queues/`);
    return data;
  } catch (error) {
    return [];
  }
}

async function testSnepQueuesNetwork() {
  try {
    const { data } = await $http.get(`${service}/companies/queues/`);
    return data;
  } catch (error) {
    if (error?.response?.data?.error === 'snep_ip or snep_password invalid!') {
      throw new Error('Credências de SNEP inválidas');
    } else {
      throw new Error(`Internal server error: ${error?.response?.data?.error}`);
    }
  }
}

async function setState(state) {
  const res = await $http.put(`${service}/companies/users/state`, {
    state: state,
  });
}

async function getAllUsers(filters) {
  const params = {};
  if (filters) {
    if (filters.status) params.status = filters.status;
    if (filters.includeWorkGroups) params.includeWorkGroups = filters.includeWorkGroups;
    if (filters.withoutOpensUsers) params.withoutOpensUsers = filters.withoutOpensUsers;
  }
  const { data } = await $http.get(`${service}/companies/users`, { params });
  return data.users;
}

async function getCompanieConfigByKey(configKey) {
  const data = await $http.get(`${service}/companies/configs/${configKey}`);
  return data;
}

async function getCompanySupportWidgetId() {
  const { data } = await $http.get(`${service}/companies/support-widget-id`);
  return data;
}

export const twoAuthLink = async function (userId) {
  const { data } = await $http.post(`${service}/authenticate/twoFactor/otp`, {
    userId,
  });
  return data;
};

export const otpValidateToken = async function (userId, code) {
  const { data } = await $http.post(`${service}/authenticate/twoFactor/otp/validate`, {
    userId,
    code,
  });
  return data;
};

export const updateEmailTwoFactor = async function (userId, two_auth_mail) {
  const { data } = await $http.put(`${service}/companies/users/${userId}`, {
    two_auth_mail,
  });
  return data;
};

export const updateOtpTwoFactor = async function (userId, two_auth_otp) {
  const { data } = await $http.put(`${service}/companies/users/${userId}`, {
    two_auth_otp,
  });
  return data;
};

export const getAllManagedUsers = async function (managerId) {
  try {
    const { data } = await $http.get(`${service}/users/managed/${managerId}`);
    return data;
  } catch (error) {
    throw new Error(`Error sending record to email: ${error}`);
  }
};

export const getUserRingingSound = async function (userId) {
  try {
    const { data } = await $http.get(`${service}/companies/user/ringing-sound/${userId}`);
    return data;
  } catch (error) {
    throw new Error(`Error getting user ringing sound: ${error}`);
  }
};

export const updateUserRingingSound = async function (ringingSound, userId) {
  try {
    const { data } = await $http.put(`${service}/companies/user/ringing-sound/${userId}`, {
      ringing_sound: ringingSound,
    });
    return data;
  } catch (error) {
    throw new Error(`Error updating user ringing sound: ${error}`);
  }
};

export default {
  getDeviceConfig,
  setState,
  getUserActions,
  changeTransport,
  getCompanyQueues,
  getUserById,
  getUserByPeer,
  getUserBond,
  addUserQueueBond,
  getQueue,
  getAllUsers,
  testSnepQueuesNetwork,
  twoAuthLink,
  otpValidateToken,
  updateEmailTwoFactor,
  updateOtpTwoFactor,
  getAllUsersBonds,
  getBondsWorkGroupById,
  getCompanyById,
  logout,
  getContactFromCservice,
  getCompanieConfigByKey,
  getCompanySupportWidgetId,
  getAllManagedUsers,
  getUserRingingSound,
  updateUserRingingSound,
  getCompanyPauses,
  getUserPause,
};
