import googlePhone from 'google-libphonenumber';

export default {
  data: function () {
    return {
      synonymMap: {
        CALL: 'Chamada telefônica',
      },
      translateWeekDayMap: {
        dom: 0,
        seg: 1,
        ter: 2,
        qua: 3,
        qui: 4,
        sex: 5,
        sab: 6,
      },
      phoneUtil: googlePhone.PhoneNumberUtil.getInstance(),
      requestLinks: {
        cservice: `${process.env.VUE_APP_SCHEMA}://${process.env.VUE_APP_CSERVICE}`,
        cases: `${process.env.VUE_APP_SCHEMA}://${process.env.VUE_APP_CASES}`,
        clist: `${process.env.VUE_APP_SCHEMA}://${process.env.VUE_APP_CLIST}`,
        callreport: `${process.env.VUE_APP_SCHEMA}://${process.env.VUE_APP_CALLREPORT}`,
        cstate: `${process.env.VUE_APP_SCHEMA}://${process.env.VUE_APP_CSTATE}`,
        cstate_socket: `${process.env.VUE_APP_CSTATE}`,
        cblist: `${process.env.VUE_APP_SCHEMA}://${process.env.VUE_APP_CBLIST}`,
        rhistory: `${process.env.VUE_APP_SCHEMA}://${process.env.VUE_APP_RHISTORY}`,
        rhistory_socket: `${process.env.VUE_APP_RHISTORY}`,
        records: `${process.env.VUE_APP_SCHEMA}://${process.env.VUE_APP_RECORDS}`,
      },
      PNF: googlePhone.PhoneNumberFormat,
      asYouTypeFormatter: googlePhone.AsYouTypeFormatter,
      fakeContact: {
        contact: {
          belongsTo: 'Não identificado',
          id: 'Não identificado',
          name: 'Não identificado',
          photo: 'test.jpg',
          organizationId: 'Não identificado',
          categoryId: 'Não identificado',
          phones: [
            {
              id: 'Não identificado',
              number: 'Não identificado',
              contactId: 'Não identificado',
            },
          ],
          emails: [
            {
              id: 'Não identificado',
              address: 'Não identificado',
              contactId: 'Não identificado',
            },
          ],
          organization: {
            name: 'Não identificado',
            id: 'Não identificado',
          },
          category: {
            id: 'Não identificado',
            name: 'Não identificado',
            color: '#F9A454',
            companyId: 'Não identificado',
          },
        },
      },
    };
  },
  methods: {
    _formatNumberForSearch(phone) {
      if (phone.startsWith(0)) {
        return phone.substring(1);
      }
      return phone;
    },
    _formatNumber(phone) {
      try {
        if (!phone) return;
        if (phone.length === 4) return 'SIP ' + phone;
        if (phone.length > 4 && (phone.startsWith('55') || phone?.ddi == '55'))
          return this.phoneUtil.format(this.phoneUtil.parseAndKeepRawInput(phone, 'BR'), this.PNF.INTERNATIONAL);

        const number = this.phoneUtil.parseAndKeepRawInput(phone.number, phone.country);
        return this.phoneUtil.format(number, this.PNF.INTERNATIONAL);
      } catch (error) {
        return phone;
      }
    },
    searchFunction(mates, inputValue) {
      const filteredMates = mates.filter((data) => {
        const pesquisa = {
          work_groups: function (work_groups, inputValue) {
            if (work_groups) {
              return work_groups[0]?.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
            } else {
              return false;
            }
          },
          category: function (category, inputValue) {
            if (category) {
              return category?.name?.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
            } else {
              return false;
            }
          },
          username: function (username, inputValue) {
            if (username) {
              return username?.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
            } else {
              return false;
            }
          },
          occupation: function (occupation, inputValue) {
            if (occupation) {
              return occupation?.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
            } else {
              return false;
            }
          },
          phones: function (phones, inputValue) {
            if (phones) {
              return phones[0]?.number.includes(inputValue);
            } else {
              return false;
            }
          },
          organization: function (organization, inputValue) {
            if (organization) {
              return organization?.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
            } else {
              return false;
            }
          },
        };
        return (
          pesquisa['work_groups'](data.work_groups, inputValue) ||
          pesquisa['category'](data.category, inputValue) ||
          pesquisa['username'](data.username, inputValue) ||
          pesquisa['occupation'](data.occupation, inputValue) ||
          pesquisa['phones'](data.phones, inputValue) ||
          pesquisa['organization'](data.organization, inputValue)
        );
      });
      return filteredMates;
    },
    async callNumber(number) {
      let numero;
      let tech_channel = false;
      if (number.startsWith('SIP')) {
        numero = number.split('SIP/')[1];
        tech_channel = 'SIP';
      } else if (number.startsWith('PJSIP')) {
        numero = number.split('PJSIP/')[1];
        tech_channel = 'PJSIP';
      } else {
        numero = number;
      }

      let from;
      from = this.user.endpoint;

      let url = `${process.env.VUE_APP_SCHEMA}://${process.env.VUE_APP_CALLS}/api/v1/call?from=${from}&to=${numero}`;
      if (tech_channel) {
        url += `&tech_channel=${tech_channel}`;
      }
      const res = await this.$http.get(url);
      this.$store.commit('cstate/SET_CHANNEL', res.data.channel);
    },
    async muteFunction(mute) {
      this.$http.post(`${process.env.VUE_APP_SCHEMA}://${process.env.VUE_APP_CALLS}/api/v1/mute`, {
        channel: this.$store.getters['cstate/GET_CHANNEL'],
        state: mute,
      });
    },
    async startSpyCall(agentEndpoint, spierEndPoint, isWhisper) {
      this.$http.post(`${process.env.VUE_APP_SCHEMA}://${process.env.VUE_APP_CALLS}/api/v1/spy`, {
        endpoint: agentEndpoint,
        spier: spierEndPoint,
        whisper: isWhisper,
      });
    },
    async holdFunction() {
      this.$http.post(`${process.env.VUE_APP_SCHEMA}://${process.env.VUE_APP_CALLS}/api/v1/hold`, {
        channel: this.$store.getters['cstate/GET_CHANNEL'],
      });
    },
    async _sendCsatCall(linkedid, { techChannel, endpoint }) {
      try {
        await this.$http.post(`${process.env.VUE_APP_SCHEMA}://${process.env.VUE_APP_CALLS}/api/v1/blindtransfer`, {
          channel: `${techChannel}/${endpoint}`,
          exten: 'csat',
        });
        await this.$http.post(`${process.env.VUE_APP_SCHEMA}://${process.env.VUE_APP_CALLREPORT}/action`, {
          linkedid,
          action: 'sent_csat',
        });
      } catch (e) {
        this.$toast({ message: `${error}` });
      }
    },
    async _transferCall(number) {
      this.$http.post(`${process.env.VUE_APP_SCHEMA}://${process.env.VUE_APP_CALLS}/api/v1/attendtransfer`, {
        channel: `${this.user.tech_channel}/${this.user.endpoint}`,
        exten: number,
      });
    },
    checkCategory(category) {
      var returnData = category;
      return returnData;
    },
    closeModal(modalId) {
      document.getElementById(modalId).style.display = 'none';
    },
    openModal(modalId) {
      document.getElementById(modalId).style.display = 'block';
    },
    optionsAnimation(searchId, blockMaxHeight, optionMaxHeight) {
      var i;
      var blockFilter = document.getElementById(searchId);
      var blockFilterOptions = blockFilter.childNodes;
      for (i = 0; i < blockFilterOptions.length; i++) {
        if (blockFilterOptions[i].style.display == '') {
          blockFilter.style.display = 'flex';
          blockFilterOptions[i].style.display = 'flex';
          setTimeout(heightMax, 1, i);
        } else {
          blockFilter.style.height = '0px';
          blockFilterOptions[i].style.height = '0px';
          setTimeout(heightMin, 50, i);
        }
      }
      function heightMax(i) {
        blockFilter.style.height = blockMaxHeight;
        blockFilterOptions[i].style.height = optionMaxHeight;
      }
      function heightMin(i) {
        blockFilter.style.display = '';
        blockFilterOptions[i].style.display = '';
      }
    },
    selectedOption(optionId, selectId) {
      var option = document.getElementById(optionId);
      var optionTxt = option.innerText;
      var optionParent = document.getElementById(selectId);
      optionParent.childNodes[0].nodeValue = optionTxt;
    },
    async _hangUpCall() {
      try {
        await this.$http.post(`${process.env.VUE_APP_SCHEMA}://${process.env.VUE_APP_CALLS}/api/v1/hangup`, {
          channel: `${this.user.tech_channel}/${this.user.endpoint}`,
        });
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async _cancelTransfer() {
      try {
        await this.$http.post(`${process.env.VUE_APP_SCHEMA}://${process.env.VUE_APP_CALLS}/api/v1/canceltransfer`, {
          channel: `${this.user.tech_channel}/${this.user.endpoint}`,
        });
      } catch (e) {
        return Promise.reject(e);
      }
    },
  },
  computed: {
    modal: function () {
      return this.$store.getters['GET_MODAL'];
    },
    accessToken: function () {
      return this.$store.getters['GET_TOKEN'];
    },
    user: function () {
      return this.$store.getters['user/GET_USER'];
    },
    userProfile: function () {
      return this.$store.getters['user/GET_USER'].profile;
    },
    companyId: function () {
      return this.$store.getters['user/GET_USER'].company_id;
    },
    devices: function () {
      return this.$store.getters['user/GET_DEVICES'];
    },
    plan() {
      return this.$store.getters['license/getPlan'];
    },
    deviceTransportConfig() {
      return this.$store.getters['sip/configuration'];
    },
    isWebRTC() {
      const config = this.$store.getters['sip/configuration'];
      if (!config) return false;
      return config.transport === 'transport-wss';
    },
  },
};
