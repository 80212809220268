<template lang="pug">
.text-inside-brand.relative.w-full.p-1(
  v-show='isVisible'
  class="min-h-[120px]"
)
  .flex.w-full.h-full.items-start.gap-2.pt-3
    .flex.flex-col.justify-start.h-full
      .profile-img-icon(v-if='mate.max_call_queue')
      .list-user-img(v-else-if='mate.createdAt' style='position: relative')
        .colega-status-disabled(
          class="z-[1]"
          :class="isDeviceOnline ? translateStatus[status] : ''" :tooltip="translateStatus[status] ? [translateStatus[status]] : 'Offline'"
        ) {{ translateStatus[status] == 'NaoPerturbe' ? '-' : '' }}
        img(v-if='!mate.invalid && mate.avatar != null' style='border-radius: 4px; width: 32px; height: 32px' :src='`${requestLinks.cservice}/avatar/${mate.id}.png`' @error='mate.invalid = true; $forceUpdate();')
        .firstLetter(v-else='') {{ mate.username[0].toUpperCase() }}
      .list-user-img(v-else='')
        .firstLetter(v-if='mate.invalid') {{ mate.username[0].toUpperCase() }}
        img(v-else-if='!mate.invalid' style='border-radius: 4px; width: 32px; height: 32px' :src='`${requestLinks.clist}/uploads/${mate.id}.jpg`' @error='mate.invalid = true; $forceUpdate(); ')
    .grid.items-start(class="w-[90%]")
      span.font-semibold.font-inter.truncate(class="dark:text-black dark:font-extrabold") {{ mate.username }}

      span.text-xs.font-inter.text-zinc-400.leading-5.truncate(class="dark:text-zinc-900", v-if='mate.organization') {{ mate.organization.name }}

      span.text-xs.font-inter.text-zinc-400.leading-5.truncate(class="dark:text-zinc-900", v-else-if='mate.occupation')
        | {{ mate.occupation.substring(0, 25) + (mate.occupation.length > 21 ? '...' : '') }}

      span.text-xs.font-inter.text-zinc-400.leading-5.truncate(class="dark:text-zinc-900" v-else-if='mate.max_call_queue') 00 agentes disponíveis

      span.text-xs.font-inter.text-zinc-400.leading-5.truncate(class="dark:text-zinc-900" v-if='mate.phones' ) {{ _formatNumber(mate.phones[0]) }}

      div(v-else-if='mate.work_groups')
        span.text-xs.font-inter.text-zinc-400.leading-5.truncate(class="dark:text-zinc-900")
          | {{ mate.work_groups[0].name }}
          a(v-if='mate.work_groups.length > 1') +{{ mate.work_groups.length }}

      span.text-xs.font-inter.text-zinc-400.leading-5.truncate
        span Ramal
        span.font-semibold {{ mate.peer_create }}

    .flex.flex-col.justify-evenly.relative.items-center
      .truncate.user-tag.flex.justify-start.absolute.top-0.colega-tag(v-if='mate?.category?.name' style='padding: 5px !important; width: auto !important; min-width: max-content important;')
        | {{ mate.category.name }}
      .truncate.user-tag.flex.justify-start.absolute.top-0.colega-tag(v-else-if='mate.createdAt' ref='aRef' style='padding: 5px !important;width: auto !important;min-width: max-content !important;')
        | Colega
      .items-center.justify-center.flex.h-full.mt-6(class="dark:invert")
        i.fa.fa-phone.items-center.justify-center.flex.rounded-full.transition-all.text-2xl.w-11.h-11(
          v-tooltip="'Ligar para ' + mate.username"
          v-if='mate.phones'
          role='button'
          @click='checkCall'
          class="hover:bg-brand-gradient hover:text-white text-[#58dca5]"
        )
        i.fa.fa-phone.items-center.justify-center.flex.rounded-full.transition-all.text-2xl.w-11.h-11(
          v-tooltip="'Ligar para ' + mate.username"
          v-else-if="mate.endpoint && isDeviceOnline"
          role='button'
          @click='checkCall'
          class="hover:bg-brand-gradient hover:text-white text-[#58dca5]"
        )
        .flex.w-11.h-11(v-else)
</template>

<script>
import eventBus from '../../main-event-bus';
import cservice from '@/services/cservice';
export default {
  props: ['mate', 'index', 'isTransfer', 'search', 'socket'],
  data() {
    return {
      myUser: {},
      isDeviceOnline: false,
      amOnline: false,
      currentPayload: null,
      socketMessage: {},
      schedule: [],
      status: 504,
      statusTemp: 504,
      isAuto: false,
      state: {
        AUTO: 10,
        CONECTADO: 100,
        DISPONIVEL: 101,
        AGENTE_DISPONIVEL: 102,
        NAO_PERTURBE: 202,
        EM_PAUSA: 303,
        FIM_PAUSA: 304,
        EM_CHAMADA: 404,
        FIM_CHAMADA: 405,
        OFFLINE: 504,
      },
      translateStatus: {
        101: 'Disponivel',
        102: 'Disponivel',
        202: 'NaoPerturbe',
        303: 'EmPausa',
        404: 'Ocupado',
        504: 'Offline',
      },
      translateCServiceStatus: {
        enabled: 101,
        disabled: 202,
        online: 101,
        auto: 10,
      },
      portugueseTranslate: {
        NaoPerturbe: 'Não Perturbe',
        EmPausa: 'Em pausa',
        Offline: 'Offline',
        Ocupado: 'Ocupado',
        Disponivel: 'Disponivel',
      },
      translateWeekDayMapReverse: {
        0: 'dom',
        1: 'seg',
        2: 'ter',
        3: 'qua',
        4: 'qui',
        5: 'sex',
        6: 'sab',
      },
      clockStart: null,
      clockEnd: null,
    };
  },
  computed: {
    hasActiveDevice() {
      return this.$store.getters['user/HAS_ACTIVE_DEVICE'];
    },
    isVisible() {
      if (!this.search || this.search.length === 0) {
        return true;
      }

      const { mate, search } = this;
      const { username, endpoint, phones, organization } = mate;

      if (username && username.toLowerCase().startsWith(search.toLowerCase())) {
        return true;
      }

      if (username && username.toLowerCase().includes(search.toLowerCase())) {
        return true;
      }

      if (endpoint && endpoint.startsWith(search)) {
        return true;
      }

      if (phones) {
        const phoneMatches = phones.filter((phone) => {
          return phone.number.includes(search) || `${phone.number}${phone.dddi}`.includes(search);
        });

        if (phoneMatches.length > 0) {
          return true;
        }
      }

      if (organization && organization.name.toLowerCase().startsWith(search.toLowerCase())) {
        return true;
      }

      return false;
    },
  },
  watch: {
    isDeviceOnline(value) {
      if (!value) return;
      switch (this.status) {
        case this.state.OFFLINE:
          {
            this.$emit('offline', this.index);
          }
          return;
        case this.state.DISPONIVEL:
          {
            this.$emit('online', this.index);
          }
          return;
        case this.state.A_DISPONIVEL:
          {
            this.$emit('online', this.index);
          }
          return;
        case this.state.EM_PAUSA:
          {
            this.$emit('paused', this.index);
          }
          return;
        case this.state.NAO_PERTURBE:
          {
            this.$emit('paused', this.index);
          }
          return;
        case this.state.NAO_PERTURBE: {
          {
            this.$emit('busy', this.index);
          }
          return;
        }
      }
    },
    status(n) {
      if (!this.isDeviceOnline) {
        this.$emit('offline', this.index);
        return;
      }
      switch (n) {
        case this.state.OFFLINE:
          {
            this.$emit('offline', this.index);
          }
          return;
        case this.state.DISPONIVEL:
          {
            this.$emit('online', this.index);
          }
          return;
        case this.state.A_DISPONIVEL:
          {
            this.$emit('online', this.index);
          }
          return;
        case this.state.EM_PAUSA:
          {
            this.$emit('paused', this.index);
          }
          return;
        case this.state.NAO_PERTURBE:
          {
            this.$emit('paused', this.index);
          }
          return;
        case this.state.NAO_PERTURBE: {
          {
            this.$emit('busy', this.index);
          }
          return;
        }
      }
    },
    statusTemp(currentStatusValue) {
      if (!this.mate.endpoint) this.status = this.state.OFFLINE;
      else this.status = currentStatusValue;
    },
  },
  async mounted() {
    try {
      this.myUser = JSON.parse(JSON.stringify(this.mate));
      if (this.mate.phones) return;
      //Fazer request pra verificar status do usuário caso ele seja agente de atendimento
      // ja que agentes de atendimento DEVEM estar logados na interface
      const user = await cservice.getUserById(this.mate.id);
      this.updateUser(user);
      this.socketConfig();
    } catch (e) {
      this.$log.error(e);
    }
  },
  beforeDestroy() {
    if (this.mate.phones) return;
    eventBus.$off(`restart-${this.myUser.id}`, this.statusListener);
    eventBus.$off(`status-agent-${this.myUser.id}`, this.statusListener);
    eventBus.$off(`peerStatus-${this.myUser.endpoint}`, this.peerStatusListener);
    eventBus.$off(`device-${this.myUser.id}`, this.statusListener);
    eventBus.$off(`updateWorkSchedule-${this.myUser.id}`, this.statusListener);
  },
  methods: {
    async statusListener(user) {
      try {
        await this.updateUser(user);
      } catch (e) {
        this.$log.error(e);
      }
    },
    async peerStatusListener(payload) {
      if (payload.online) this.updateUser(this.myUser);
      else {
        this.isDeviceOnline = false;
        this.statusTemp = this.state.OFFLINE;
      }
    },
    checkCall() {
      const num = this.myUser.endpoint || this.myUser.phones[0].number;
      if (this.isTransfer) {
        this.$emit('calling', { contact: this.myUser, num: num });
      } else {
        if (this.$store.getters['sip/agent']) this.$store.dispatch('sip/makeCall', { callNum: num });
        else this.callNumber(num);
      }
      eventBus.$emit('UX-event', {
        eventName: 'dialpad-mate-make-call',
        payload: {
          toNumber: num,
          toId: this?.myUser?.id,
        },
      });
    },
    socketConfig() {
      eventBus.$on(`restart-${this.myUser.id}`, this.statusListener);
      eventBus.$on(`status-agent-${this.myUser.id}`, this.statusListener);
      eventBus.$on(`peerStatus-${this.myUser.endpoint}`, this.peerStatusListener);
      eventBus.$on(`device-${this.myUser.id}`, this.statusListener);
      eventBus.$on(`updateWorkSchedule-${this.myUser.id}`, this.statusListener);
    },
    // SEPARA HORARIOS DE TRABALHO PELO DIA DE HJ EM UMA UNICA PILHA EM ORDEM CRESCENTE
    parseWorkSchedule() {
      if (!this.myUser.users_schedules) {
        this.schedule = [];
        return;
      }
      if (!this.myUser.users_schedules.length) {
        this.schedule = [];
        return;
      }

      const schedules = this.parseWorkScheduleDays(this.myUser.users_schedules);
      const stack = this.parseWorkScheduleHours(schedules);
      this.schedule = stack;
    },
    parseWorkScheduleDays(myUserSchedules) {
      const currentDay = this.translateWeekDayMapReverse[new Date().getDay()];
      const horarios = myUserSchedules.filter((schedule) => {
        if (schedule.day.includes(currentDay) && schedule.enabled) return true;
        return false;
      });
      return horarios;
    },
    parseWorkScheduleHours(range) {
      const stack = [];
      const d = new Date();
      const hour = (d.getHours() * 60 + d.getMinutes()) * 60 * 1000;

      range.forEach((day) => {
        let startTime = parseInt(day.start_time.split(':')[0]) * 60 + parseInt(day.start_time.split(':')[1]);

        let endTime = parseInt(day.end_time.split(':')[0]) * 60 + parseInt(day.end_time.split(':')[1]);

        const schedule = {
          start: startTime * 60 * 1000,
          end: endTime * 60 * 1000,
        };

        if (hour <= schedule.end) {
          const i = this.sortedIndex(stack, schedule.start);
          stack.splice(i, 0, schedule);
        }
      });
      return stack;
    },
    autoMode() {
      if (!this.schedule.length) {
        this.statusTemp = this.state.OFFLINE;
        clearTimeout(this.clockEnd);
        clearTimeout(this.clockStart);
        return;
      }

      const d = new Date();
      const hour = (d.getHours() * 60 + d.getMinutes()) * 60 * 1000;

      const faixa_atual = this.schedule.shift();
      const tempoFaltando = faixa_atual.start - hour;

      if (tempoFaltando >= 0) {
        this.statusTemp = this.state.OFFLINE;
        this.clockStart = setTimeout(() => {
          this.statusTemp = this.state.DISPONIVEL;
        }, Math.max(tempoFaltando));
      } else this.statusTemp = this.state.DISPONIVEL;

      this.clockEnd = setTimeout(() => {
        this.autoMode();
      }, Math.max(0, faixa_atual.end - hour));
    },
    initAuto() {
      this.isAuto = true;
      clearTimeout(this.clockStart);
      clearTimeout(this.clockEnd);
      this.parseWorkSchedule();
    },
    clearAuto() {
      this.isAuto = false;
      clearTimeout(this.clockStart);
      clearTimeout(this.clockEnd);
    },
    sortedIndex(arr, val) {
      var low = 0,
        high = arr.length;

      while (low < high) {
        var mid = (low + high) >>> 1;
        if (arr[mid]['start'] < val) low = mid + 1;
        else high = mid;
      }
      return low;
    },
    async verifyUserStatus() {
      if (!this.myUser) return;
      if (this.myUser.oncall) {
        this.status = 404;
        this.statusTemp = this.state.EM_CHAMADA;
      } else if (this.myUser.pause) {
        this.statusTemp = this.state.EM_PAUSA;
      } else if (this.myUser.state == 'auto') {
        this.statusTemp = this.state.AUTO;
      } else {
        if (this.myUser.state) {
          const initStatus = this.translateCServiceStatus[this.myUser.state];
          if (this.myUser.profile == 'p_agent') {
            await this.checkOnlineStatus();
          }
          if (!this.myUser.endpoint) this.statusTemp = this.state.OFFLINE;
          else if (this.myUser.profile == 'p_agent' && !this.amOnline) this.statusTemp = this.state.OFFLINE;
          else if (this.myUser.profile == 'p_agent' && this.myUser.pause) this.statusTemp = this.state.EM_PAUSA;
          else this.statusTemp = initStatus;
        } else {
          this.statusTemp = this.state.OFFLINE;
        }
      }
    },
    async updateUser(user) {
      try {
        this.myUser = user;

        // verifica se endpoint esta online ou não
        const cstRes = await this.$http.get(`${this.requestLinks.cstate}/online/device/${this.myUser.endpoint}`);
        if (cstRes.data.status) this.status = 101;
        this.isDeviceOnline = cstRes.data.status;
      } catch (e) {
      } finally {
        this.verifyUserStatus();
      }
    },
    async checkOnlineStatus() {
      try {
        const res = await this.$http(`${this.requestLinks.cstate}/online/${this.myUser.id}`);
        this.amOnline = res.data;
      } catch (e) {
        return false;
      }
    },
  },
};
</script>

<style>
.mate-card-right {
  width: 20%;
  display: flex;
  flex-direction: column;
}
.mate-card-right-up {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.mate-card-right-bottom {
  width: 100%;
  height: 40%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.mate-card-center {
  width: 70%;
  display: flex;
  justify-content: left;
  flex-direction: column;
}
*[tooltip] {
  position: relative;
  border-bottom: dotted 1px #000;
}
*[tooltip]:hover:before {
  content: attr(tooltip);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 6px;
  position: relative;
  width: fit-content;
  height: 14px;
  background: #343a40;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  color: #ffffff;
  border-radius: 4px;
  top: 1em;
  right: -10px;
  position: absolute;
  white-space: nowrap;
  animation: 1s tooltipAnimation ease;
}
@keyframes tooltipAnimation {
  0% {
    height: 0px;
    opacity: 0;
  }
  59% {
    height: 0px;
    opacity: 0;
  }
  70% {
    height: 14px;
    opacity: 1;
  }
}
.Disponivel {
  background-color: var(--green-default) !important;
}
.NaoPerturbe {
  background-color: var(--yellow-default) !important;
}
.Ocupado {
  background-color: var(--red-default) !important;
}
.EmPausa {
  background-color: var(--yellow-default) !important;
}
.unknown-user {
  background-image: linear-gradient(156.96deg, var(--brand-color) 9.13%, var(--brand-orange) 103.34%) !important;
  font-size: 28px !important;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.card-info-text {
  width: fit-content;
  height: auto;
}
.call-icon {
  background: none;
  border: none;
  box-shadow: none !important;
  font-size: 24px !important;
  color: var(--green-default);
}
.card-margin {
  margin-top: 6px;
  margin-left: 8px;
}
.Cliente {
  width: auto;
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background: rgba(74, 142, 244, 0.1);
  color: #4a8ef4;
}
.Lead {
  width: auto;
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background: rgba(220, 96, 88, 0.1);
  color: #dc6058;
}
.Parceiro {
  width: auto;
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background: rgba(179, 115, 242, 0.1);
  color: #b373f2;
}
.Colega {
  width: auto;
  margin-top: 5px;
  display: flex;
  justify-content: center;
  background: none !important;
  align-items: center;
  padding: 5px;
  border: 1px solid rgba(249, 164, 84, 0.2) !important;
  color: var(--yellow-default) !important;
}
.colega-name-text {
  margin-left: 5px;
  font-family: 'Inter', sans-serif;
  max-width: 18ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #495057 !important;
}
.colega-card-aligner {
  width: 12.5%;
  position: absolute;
  display: flex;
  justify-content: flex-end;
  height: 1%;
}
.colega-status-disabled {
  border: 1.5px solid #fff;
  width: 12px;
  top: 75%;
  left: 80%;
  font-weight: bold;
  user-select: none;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  height: 12px;
  border-radius: 8px;
  background-color: #adb5bd;
}
.profile-img-box {
  display: flex;
  width: 35px;
  height: 35px;
  flex-direction: column-reverse;
}
.profile-img-icon {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 32px;
  height: 32px;
  background: rgba(249, 164, 84, 0.1);
  border-radius: 4px;
}
.colega-card {
  width: 280px;
  padding: 12px 16px 12px 16px;
  height: 95px;
}
</style>
<style scoped>
.card-info-text {
  width: auto;
  text-align: left;
}
</style>
