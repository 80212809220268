<template lang="pug">
modal(mo2)
  modal-body(class="w-[38rem]")
    template(v-slot:header)
      .flex.justify-between
        h4.text-2xl.text-gray-800.font-semibold.font-plus Atribuir retorno
        .material-icons.notranslate.cursor-pointer(@click="$emit('close-modal')") close

    template(v-slot:body)
      p.font-plus.font-semibold.text-slate-600.mb-2 Ligar para
      span.flex.gap-2.justify-start.items-start(v-if="name")
        .callback-callto-user-img(v-if="failedImg") {{ name[0].toUpperCase() }}
        img.callback-callto-user-img(
          v-else-if="!failedImg",
          :src="`${requestLinks.clist}/uploads/${contact.id}.jpg`",
          @error="failedImg = true"
        )
        .flex.flex-col.ml-4.items-start
          a.font-plus.truncate.font-normal.self-start.max-w-xs(v-tooltip="name") {{ name }}
          a.card-info-text(style="text-align: left" v-if="phone") {{ phone }}
          a.card-info-text(style="text-align: left" v-else-if="contact.organization") {{ contact.organization.name }}
        .callback-callto-tag-margin.user-tag(v-if="contact.category?.name") {{ contact.category.name }}
        .user-tag(
          v-else-if="contact.email",
          :style="'color:#F9A454;border: 1px solid rgba(249, 164, 84, 0.2);margin-left:5px'"
        ) Colega
        .unknown-text.no-tag-margin(v-else) Sem categoria

      span.flex.gap-2.justify-start.items-start(v-else)
        .callback-callto-user-img CD
        .contact-info-aligner
          a.colega-name-text.no-margin Contato Desconhecido
          a.card-info-text {{ contact.endpoint }}
        .unknown-text.no-tag-margin Sem categoria

      v-dropdown-v2.my-4(
        :placeholder="callback.username.length ? callback.username : 'Selecione um responsável'"
        label="Responsável"
      )
        span.mb-2.w-full
          v-input(label="Buscar por nome" v-model="searchUserByName")
        span.overflow-auto.w-full
          dropdown-item-v2(
            v-for="user in filteredUsers"
            :selected="callback.assignedTo === user.id"
            :text="user.username"
            :key="user.id"
            @click="handleSelectUser(user)"
            v-close-popper
          )

      a.modal-tag Observações
      textarea.form-control.note-input(
        v-model="noteValue",
        style="margin-top: 8px; height: 77px; width: 100%",
        placeholder="Observação"
      )

    template(v-slot:footer)
      .flex.justify-center.gap-2
        v-button-secondary.h-full(v-on:click="$emit('close-modal')" :disabled="loading") Cancelar
        v-button.h-full(v-on:click="addCallBack" :disabled="loading") Concluir
</template>

<script>
import ModalBody from '@/components/Modals/ModalBody.vue';
import Modal from '@/components/Modals/Modal.vue';
import VButtonSecondary from '@/components/Buttons/v-button-secondary.vue';
import VButton from '@/components/Buttons/v-button.vue';
import CService from '@/services/cservice';
import VDropdownV2 from '@/components/Dropdown/v-dropdown-v2.vue';
import DropdownItemV2 from '@/components/Dropdown/dropdown-item-v2.vue';
import VInput from '@/components/Inputs/IconInput.vue';
import { createCallback } from '@/services/cblist';
import { getContactById } from '@/services/contact-list';

export default {
  components: {
    VButton,
    VButtonSecondary,
    Modal,
    ModalBody,
    VDropdownV2,
    DropdownItemV2,
    VInput,
  },
  props: ['contact', 'linkedId'],
  data: () => ({
    callback: {
      user: '',
      assignedTo: '',
      username: '',
    },
    failedImg: false,
    searchUserByName: '',
    companyUserList: [],
    noteValue: '',
    loading: false,
  }),
  computed: {
    name() {
      return this.contact.name || this.contact.username;
    },
    phone() {
      if (this.contact.phones) {
        return this.contact.phones[0].number;
      }
      return this.contact.number || this.contact.endpoint;
    },
    filteredUsers() {
      return this.companyUserList.filter((user) =>
        user.username.toLowerCase().includes(this.searchUserByName.toLowerCase()),
      );
    },
    isUserSelected() {
      return this.callback.assignedTo.length;
    },
  },
  methods: {
    handleSelectUser(user) {
      this.callback.assignedTo = user.id;
      this.callback.username = user.username;
    },
    async addCallBack() {
      if (!this.isUserSelected) {
        this.$toast.error('Selecione um responsável!');
        return;
      }

      try {
        this.loading = true;

        let phone = '';
        let email = '';
        let note = this.noteValue;
        let itemId = this.$store.getters['GET_ITEMID'];
        if (!this.contact.endpoint) {
          const contact = await getContactById(itemId);
          phone = contact.phones[0]?.number;
          email = contact.emails[0]?.address;
        }

        await createCallback({
          type: 'CALL',
          status: 101,
          category: 'Phone',
          assignedTo: this.callback.assignedTo,
          assignedBy: this.user.id,
          assignedByName: this.user.username,
          contactNumber: phone || this.phone,
          contactEmail: this.contact.email || email,
          linkedid: this.linkedId,
          note: note,
        });

        this.$emit('close-modal');

        this.$toast.success('Retorno atribuído com sucesso!');
        this.$store.commit('SET_ITEMID', null);
        this.$emit('add-callback-event', this.callback.assignedTo);
      } catch (e) {
        this.$toast.error('Falha ao atribuir retorno. Por favor, tente novamente.');
        this.$store.commit('SET_ITEMID', null);
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },
  async created() {
    this.companyUserList = await CService.getAllUsers({ status: 'activated', withoutOpensUsers: true });
  },
};
</script>
