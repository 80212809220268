<template lang="pug">
.callback-contact.flex.items-center.justify-start(:class="{'cursor-not-allowed' : isEmail && (!contact.emails?.length || !contact.emails[0]?.address)}" @click="$emit('click', contact)")
    .callback-callto-user-img.rounded-full.h-11.w-11.aspect-square(v-if="failedImg") {{ name[0] }}
    img.rounded-full.h-11.w-11.aspect-square.object-cover.border.border-brand-200(
        v-else-if="!failedImg && !contact.avatar",
        :src="`${requestLinks.clist}/uploads/${contact.id}.jpg`",
        @error="failedImg = true"
    )
    img.rounded-full.h-11.w-11.aspect-square.object-cover(
        v-else-if="!failedImg && contact.avatar",
        :src="`${requestLinks.cservice}/avatar/${contact.id}.png`",
        @error="failedImg = true"
    )
    .contact-info-aligner
        p.text-gray-900.font-plus.text-sm.font-semibold.truncate.max-w-xs(v-tooltip="name") {{ name }}
        p.text-gray-500.font-plus.text-xs.font-medium.leading-4(style="text-align: left;" v-if="contact.organization") {{ contact.organization?.name }}
        p.text-gray-500.font-plus.text-xs.font-medium.leading-4(style="text-align: left;" v-else-if="contact.emails") {{ contact.emails[0]?.address }}
        p.text-gray-500.font-plus.text-xs.font-medium.leading-4(style="text-align: left", v-if="number") {{ number }}
    .callback-callto-tag-margin.user-tag(v-if="contact.category") {{ contact.category.name }}
    .ml-2.user-tag.colega-tag(
    v-else-if="contact.email",
    ) Colega
    .unknown-text.no-tag-margin(v-else) Sem categoria
    .flex.ml-auto.gap-1(v-if="isEmail && (!contact.emails?.length || !contact.emails[0]?.address)")
      .material-icons.notranslate.text-red-600.text-sm error
      p.text-red-600.text-xs Não possui email cadastrado
    .material-icons.notranslate.ml-auto.text-brand-400(v-if="selected") check_circle
</template>

<script>
export default {
  props: ['contact', 'selected', 'isEmail'],
  data() {
    return {
      failedImg: false,
    };
  },
  computed: {
    number() {
      if (!this.contact) return '';
      if (this.contact?.number) return this.contact.number;
      return this.contact.phones ? this.contact.phones?.[0]?.number : this.contact.endpoint;
    },
    name() {
      return this.contact.name ? this.contact.name : this.contact.username || 'Desconhecido';
    },
  },
};
</script>

<style lang="scss" scoped>
.callback-contact {
  text-indent: 0;
  margin-top: 0;
  padding: 0.4rem;
  cursor: pointer;
  &:hover {
    background: rgba(233, 236, 239, 0.4);
  }
}
</style>
