<template>
  <div class="activity-card-body">
    <tr v-if="activity.contact" class="activity-card-body">
      <th class="callback-table-starter align-center-row" scope="row">
        <div class="history-type-icon">
          <div :class="'history-track-icon ' + mapCallType[activity.status]">
            <span class="text-white history-call-indicator-icon material-icons notranslate">{{
              'call_' + translateCallType(activity)
            }}</span>
          </div>
          <span class="history-type-text">{{ textForCallType[translateCallType(activity)] }}</span>
        </div>
      </th>
      <td class="callback-callto-content">
        <span class="history-type-text">{{ activityDateFormat(activity) }}</span>
      </td>
      <td class="callback-callto-content">
        <div class="callback-callto-content-aligner">
          <div class="flex" v-if="!activity.contact?.unknown">
            <div v-if="failedImg" class="callback-callto-user-img">
              {{ activity.contact.name[0] }}
            </div>
            <img
              alt="contact image"
              v-else-if="!failedImg"
              class="callback-callto-user-img"
              :src="activity.contact.avatarUrl"
              @error="failedImg = true"
            />
          </div>
          <div v-else class="callback-callto-user-img">
            {{ activity.contact.name[0] }}
          </div>
          <div class="callback-callto-info-aligner">
            <div class="flex flex-col items-start justify-start">
              <p class="callback-callto-name-text max-w-xs truncate" v-tooltip="activity.contact.name">
                {{ activity.contact.name }}
              </p>
              <p class="text-sm text-zinc-400">
                {{ activity.contact.number }}
              </p>
            </div>
            <p v-if="activity.contact.occupation" class="callback-callto-number-text">
              {{ activity.contact.occupation }}
              {{ activity.contact.queues ? ' - ' + getFirstQueueName(activity.contact.queues) : '' }}
            </p>
            <p v-else-if="activity.contact.endpoint" class="callback-callto-number-text">
              {{ activity.contact.endpoint }}
            </p>
            <p v-else class="callback-callto-number-text">
              {{ activity.contact.callerNumber }}
            </p>
          </div>
          <div v-if="activity.contact.endpoint" class="callback-callto-tag-margin user-tag colega-tag">Colega</div>
          <div v-else-if="activity.contact.category" class="callback-callto-tag-margin user-tag">
            {{ activity.contact.category.name }}
          </div>
        </div>
      </td>
      <td class="callback-callto-content">
        <span class="history-type-text">{{ activity.queue || '' }}</span>
      </td>
      <td class="callback-callto-content">
        <span class="history-type-text">{{
          activity.duration ? formatDuration(activity.duration) : getTimeWaiting(activity)
        }}</span>
      </td>
    </tr>
  </div>
</template>

<script>
export default {
  props: ['data'],
  data() {
    return {
      loaded: true,
      failedImg: false,
      mapCallType: {
        ANSWERED: 'received',
        NOANSWER: 'missed',
      },
      textForCallType: {
        made: 'Chamada efetuada atendida',
        received: 'Chamada recebida atendida',
        missed: 'Chamada recebida não atendida',
        missed_outgoing: 'Chamada efetuada não atendida',
      },
    };
  },
  computed: {
    activity() {
      return this.data;
    },
  },
  methods: {
    getFirstQueueName(queues) {
      for (const queue in queues) {
        return queue;
      }
    },
    formatDuration(seconds) {
      return new Date(seconds * 1000).toISOString().substr(11, 8);
    },
    getTimeWaiting(item) {
      return new Date(new Date(item.hangupdate) - new Date(item.calldate)).toISOString().substr(11, 8);
    },
    translateCallType(item) {
      let idToSearch = this.user.id;

      const returnIcon = {
        ANSWERED: function (call) {
          if (call.from_ids.includes(idToSearch)) {
            return 'made';
          }
          return 'received';
        },
        NOANSWER: function (call) {
          if (call.from_ids.includes(idToSearch)) {
            return 'missed_outgoing';
          }
          return 'missed';
        },
      };
      let type = returnIcon[item.status];
      return type(item);
    },
    activityDateFormat(item) {
      const date = this.$moment.tz(item.calldate, this.user.timezone);
      return date.format('DD/MM/yyyy [-] HH:mm');
    },
  },
};
</script>

<style>
.activity-card-body {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e9ecef;
}
</style>
