<template lang="pug">
tr.history-table-row(v-if="loaded")
  th.callback-table-starter(scope="row")
    .history-type-icon.flex.items-center.justify-start
      div(:class="'history-track-icon ' + mapCallType[firstStep.status.toUpperCase()]")
        span.history-call-indicator-icon.material-icons.notranslate {{ "call_" + translateCallType(firstStep) }}
      span.history-type-text.text-marker {{ textForCallType[translateCallType(firstStep)] }}
  td.callback-callto-content
    span.history-type-text.text-marker {{ activityDateFormat(call.createdAt)}}
  td.callback-callto-content
    .callback-callto-content-aligner
      .callback-callto-user-img(v-if="failedImg") {{ identityContact?.name[0]?.toUpperCase()}}
      img.callback-callto-user-img(
        v-else-if="!failedImg",
        :src="identityContact?.avatarUrl",
        @error="failedImg = true"
      )
      .callback-callto-info-aligner
        p.callback-callto-name-text.text-marker.max-w-xs.truncate(v-tooltip="identityContact?.name") {{ identityContact?.name }}
        p.callback-callto-number-text(class="dark:text-gray-800 dark:font-semibold") {{ identityContact.number || contactNumber}}
        p.callback-callto-number-text(class="dark:text-gray-800 dark:font-semibold" v-if="identityContact?.tag")
          | {{ identityContact?.tag }}
          | {{ identityContact?.queues ? " - " + getFirstQueueName(identityContact.queues): "" }}
      .callback-callto-tag-margin.user-tag.colega-tag(
        v-if="identityContact?.endpoint",
      ) Colega
  td.callback-callto-content
    span.history-type-text {{ firstStep.queue || "Nenhuma" }}
  td.callback-callto-content
    span.history-type-text.text-marker {{ duration }}
tr.history-table-row(v-else)
  td.align-middle.px-4(v-for="n in 5" :key="n")
    content-placeholders(:rounded="true").w-full
      content-placeholders-img.w-full.h-14

</template>

<script>
import { defineComponent } from 'vue';
import UserOrContactMixin from '@/mixins/user-or-contact.mixin';

export default defineComponent({
  mixins: [UserOrContactMixin],
  props: ['call'],
  data() {
    return {
      firstStep: null,
      failedImg: false,
      loaded: false,
      activity: {},
      mapCallType: {
        ANSWERED: 'received',
        NOANSWER: 'missed',
      },
      textForCallType: {
        made: 'Chamada efetuada atendida',
        received: 'Chamada recebida atendida',
        missed: 'Chamada recebida não atendida',
        missed_outgoing: 'Chamada efetuada não atendida',
      },
    };
  },
  computed: {
    duration() {
      const start = this.$moment(this.call.createdAt);
      const end = this.$moment(this.call.hungupAt);
      return this.$moment.utc(end.diff(start, 'milliseconds')).format('HH:mm:ss');
    },
    contactNumber() {
      const fromId = this.firstStep.fromId;
      const isMineCall = fromId === this.user.id;
      return isMineCall ? this.firstStep.toNumber : this.firstStep.fromNumber;
    },
  },
  methods: {
    getFirstQueueName(queues) {
      for (const queue in queues) {
        return queue;
      }
    },
    formatInputValue(number) {
      try {
        if (number.length > 4 && (number.startsWith('55') || number?.ddi == '55')) {
          return this.phoneUtil.format(this.phoneUtil.parseAndKeepRawInput(number, 'BR'), this.PNF.INTERNATIONAL);
        } else if (number.length === 4) {
          return 'SIP ' + number;
        }
        return number;
      } catch (error) {
        return number;
      }
    },
    formatDuration(seconds) {
      return seconds ? new Date(seconds * 1000).toISOString().substr(11, 8) : '???';
    },
    translateCallType(item) {
      const idToSearch = this.user.id;
      const fromIds = this.call.steps.map((step) => step.fromId);

      const returnIcon = {
        ANSWERED: () => {
          return fromIds.includes(idToSearch) ? 'made' : 'received';
        },
        NOANSWER: () => {
          return fromIds.includes(idToSearch) ? 'missed_outgoing' : 'missed';
        },
      };

      const type = returnIcon[item.status.toUpperCase()];
      return type ? type() : null;
    },
    activityDateFormat(callDate) {
      const date = this.$moment.tz(callDate, this.user.timezone);
      return date.format('DD/MM/yyyy [-] HH:mm');
    },
    async getContact() {
      const fromId = this.firstStep.fromId;
      const isMineCall = fromId === this.user.id;
      const callerId = isMineCall ? this.firstStep.toId : fromId;

      await this.setContactOrOperator({
        id: callerId,
        number: this.contactNumber,
      });
    },
  },
  async created() {
    try {
      this.firstStep = this.call.steps[0];
      await this.getContact();
    } catch (error) {
      console.error(error);
    } finally {
      this.loaded = true;
    }
  },
});
</script>
<style lang="scss" scoped>
.material-icons.notranslate {
  color: #fafafa;
}
</style>
<style>
.relative {
  position: relative;
}
.history-table-tags-content {
  display: flex;
  align-items: center;
  height: 80px;
  flex-wrap: nowrap;
  min-width: max-content;
  flex-direction: row;
}
</style>
