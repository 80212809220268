<template lang="pug">
div

  modal(v-if="showNewDialerModal")
    modal-body(class="w-[30rem]")
      template(v-slot:header)
        .flex.w-full.justify-between.items-center
          h4.text-2xl.text-gray-600.font-semibold.font-plus ✨ Novo Discador Disponível
          .material-icons.notranslate.cursor-pointer.text-gray-500(
            class="hover:text-red-500",
            @click="ignoreDialerAlert",
            v-tooltip="'Fechar'"
          ) close
      template(v-slot:body)
        h3.text-xl.font-semibold.text-brand.font-plus 🚀 Mais Performance e Design Aprimorado!
        .flex.flex-col.gap-4.mt-4
          p.font-plus 🎉 Temos uma novidade para você! Acabamos de liberar o novo discador, desenvolvido para oferecer mais performance, um design mais clean e uma experiência ainda melhor.
          p.font-plus 📱 Agora, o discador foi reposicionado para a parte superior da tela, permitindo que o espaço anteriormente utilizado seja otimizado para aprimorar ainda mais os contextos de atendimento dos clientes.
          .flex.flex-col.gap-2.items-start.border.rounded-lg.p-2
            p.text-gray-600.font-plus ℹ️ Caso prefira, você ainda pode voltar à versão anterior do discador. Basta acessar o menu:
            app-button(
              small,
              link,
              @click="goToPreferences"
            ) Preferências → Configurações do discador → Discador antigo
          p.font-plus ⭐ Experimente o novo discador e aproveite essa melhoria pensada para tornar sua rotina ainda mais eficiente! 🚀
      template(v-slot:footer)
        app-button(
          @click="ignoreDialerAlert"
        ) Entendi

  .w-full.pl-6.pt-4.pr-2(v-if="!hideDialerAlert && hasAccessToNewDialer")
    .flex.border.rounded-lg.bg-white.items-center.justify-start.p-2.gap-4.px-4.relative
      app-icon celebration
      .flex.flex-col.items-start
        h3.text-lg.font-semibold.font-plus.text-brand Novo recurso disponível
        .flex.gap-1.items-center
          p.text-gray-600.text-sm.font-plus Você está usando a nova versão do discador
          app-button(
            small,
            link,
            @click="showNewDialerModal = true"
          ) Clique aqui para saber mais sobre as melhorias.

  span
    iframe.w-screen.h-screen(
      :src="`${clientV2Url}/dashboard?authToken=${accessToken}`"
      frameborder="0"
    )
</template>

<script>
import AppButton from '@/components/Buttons/app-button.vue';
import ModalBody from '@/components/Modals/ModalBody.vue';
import AppIcon from '@/components/Icons/app-icon.vue';
import Modal from '@/components/Modals/Modal.vue';
import EventBus from '@/main-event-bus';

export default {
  title: 'Opens - Dashboard',
  components: {
    ModalBody,
    AppButton,
    AppIcon,
    Modal,
  },
  data() {
    return {
      showNewDialerModal: false,
      hideDialerAlert: false,
      newDialerWhitelist: process.env.VUE_APP_NEW_DIALER_WHITE_LIST,
    };
  },
  computed: {
    clientV2Url() {
      const baseUrl = process?.env?.VUE_APP_CLIENT_V2;
      return baseUrl;
    },
    hasAccessToNewDialer() {
      return this.newDialerWhitelist?.split(',').includes(this.user.company_id);
    },
  },
  methods: {
    onMessage(event) {
      if (event.data?.type === 'redirect') {
        this.$router.push(event.data?.path);
      }
    },
    ignoreDialerAlert() {
      localStorage.setItem('@yunique:hide-dialer-alert', true);
      EventBus.$emit('mixpanel-event', {
        eventName: 'closed-dialer-alert',
        payload: {},
      });
      this.showNewDialerModal = false;
      this.hideDialerAlert = true;
    },
    goToPreferences() {
      localStorage.setItem('@yunique:hide-dialer-alert', true);
      EventBus.$emit('mixpanel-event', {
        eventName: 'want-to-go-back-to-old-dialer',
        payload: {},
      });
      this.$router.push('/preferences/devices');
    },
  },
  mounted() {
    this.hideDialerAlert = localStorage.getItem('@yunique:hide-dialer-alert') || false;
    window.addEventListener('message', this.onMessage);
  },
  beforeDestroy() {
    window.removeEventListener('message', this.onMessage);
  },
};
</script>

<style>
@import './_style.scss';
</style>
