<template lang="pug">
modal(@click="$store.commit('modal/setActiveModal', '')")
  modal-body(class="max-w-[90vw] w-full sm:max-w-lg max-h-[90vh] overflow-y-auto")
    template(#header)
      .flex.space-between.w-full.items-center
        h4.text-2xl.text-gray-500.font-semibold.font-inter Adicionar anotação
        i.cancel.material-icons.notranslate.cursor-pointer(
          @click="$store.commit('modal/setActiveModal', '')"
        ) &#xe5cd
    template(#body)
      .flex.flex-col.w-full
        loading-ring.p-12(v-if="isLoading")
        .flex.flex-col.justify-center.items-center.w-full(v-else)
          .flex.flex-col.justify-start.items-start.w-full
            a.text-zinc-400.font-bold Anotação
            textarea.form-control.note-context-input(
              v-model="notevalue",
              placeholder="Insira uma anotação"
            )
          .ul.w-full.p-1.overflow-auto.max-h-80.w-full(v-if="notes.length")
            span.text-zinc-400.font-bold Anotações
            li.list-none(v-for="note in notes", :key="note.id")
              .flex.justify-between.items-start.p-4.border.rounded.overflow-x-auto
                p {{ note.text }}
              .flex.justify-end.items-end.px-2.mt-1
                .flex.justify-center-items-center
                  p.text-zinc-400.text-sm.mr-2.text-sm Adicionado por:
                  span.font-bold.text-zinc-500.text-sm {{note.identifier}}
          .flex.w-full.h-20.bg-zinc-100.border.text-zinc-400.font-inter(v-else, class="min-h-[5rem]") Nenhuma anotação foi feita ainda!

    template(#footer)
      .flex.w-full.items-center.justify-center.gap-2
        v-button-secondary(@click="$store.commit('modal/setActiveModal', '')") Cancelar
        app-button(@click="addnote", :loading="isAddingNote") Adicionar
</template>

<script>
import rhService from '@/services/rship.js';
import VButtonSecondary from '@/components/Buttons/v-button-secondary.vue';
import LoadingRing from '../LoadingRing.vue';
import Modal from '@/components/Modals/Modal.vue';
import ModalBody from "@/components/Modals/ModalBody.vue";
import AppButton from "@/components/Buttons/app-button.vue";

export default {
  components: {
    VButtonSecondary,
    LoadingRing,
    Modal,
    ModalBody,
    AppButton
  },
  data() {
    return {
      notevalue: '',
      notes: [],
      isAddingNote: false,
      isLoading: true,
    };
  },
  computed: {
    context() {
      return this.$store.getters['modal/getContext'];
    },
  },
  methods: {
    async removeNote(data, index) {
      try {
        await rhService.removeTag(data.id, data.callId);
        this.getTags();
        this.$toast.success('Anotação removida!');
      } catch (e) {
        this.$toast.error('Desculpe, mas não foi possivel deletar essa anotação. Tente novamente mais tarde');
      }
    },
    async addnote() {
      this.isAddingNote = true;
      try {
        await rhService.createTag({
          eventType: 'note',
          companyId: this.user.company_id,
          identifier: this.user.username,
          contact: this.context.contact,
          callId: this.context.callId,
          text: this.notevalue,
        });
        this.$store.commit('modal/setActiveModal', '');
        this.$toast.success('Anotação adicionada');
      } catch (e) {
      } finally {
        this.isAddingNote = false;
      }
    },
    async getTags() {
      try {
        const note = await rhService.getTagsById(this.context.callId);
        this.notes = note.filter((e) => e.eventType === 'note');
      } catch (e) {}
    },
  },
  async created() {
    await this.getTags();
    this.isLoading = false;
  },
};
</script>
