<template lang="pug">
.w-full.h-12
  .flex.gap-5.items-center
    span.material-icons.notranslate.w-6.h-6.rounded-full.grid.place-items-center.bg-blue-500.text-white.text-base schedule
    span.font-normal.text-base.text-zinc-600.font-inter Esperando na fila <b>{{queue?.name}}</b>
    span.ml-auto.font-normal.text-sm.font-inter(
      class="!text-[#868e96]"
      v-tooltip="formatTimestamp(data.createdAt)"
    ) {{formatTimeDiff(data.createdAt, calldate)}}
</template>

<script>
import { dateFormatMixin } from '@/mixins/date-format.mixin';
import chatConfig from '@/services/chat-config';

export default {
  name: 'queuewaiting',
  mixins: [dateFormatMixin],
  props: ['data', 'calldate'],
  data() {
    return {
      queue: {},
    };
  },
  async created() {
    const { getQueueById } = chatConfig;

    try {
      const data = await getQueueById(this.data.destination);
      this.queue = data;
    } catch (error) {
      console.error(error);
    }
  },
};
</script>

