<template lang="pug">
.notranslate.flex.items-center.justify-center(:class="[size, textColor, iconTypeClass]")
  slot
</template>

<script>
export default {
  props: {
    small: Boolean,
    large: Boolean,
    base: Boolean,
    light: Boolean,
    dark: Boolean,
    outlined: Boolean,
    primary: Boolean,
  },
  computed: {
    size() {
      if (this.small) return '!text-xs w-4 h-4';
      if (this.large) return '!text-2xl w-8 h-8';
      return '!text-base w-6 h-6';
    },
    textColor() {
      if (this.primary) return 'text-brand-500';
      if (this.light) return 'text-neutral-800 dark:text-white';
      if (this.dark) return 'text-white dark:text-neutral-800';
      return 'text-gray-600';
    },
    iconTypeClass() {
      if (this.outlined) return 'material-symbols-outlined';
      return 'material-icons';
    },
  },
};
</script>
