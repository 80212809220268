<template lang="pug">
.flex.flex-col.w-full.h-screen.items-start.justify-start.p-8(v-if="!loaded")
  .incall-left-aligner(style="flex-direction: column; display: flex")
    div(@click="$router.push({ name: 'Contatos' })" role="button" style="flex-direction: row; display: flex")
      span.arrow-icon.material-icons(style="padding-left: 0px; min-width: 0px; width: 20px") &#xe5c4
      a.back-text(style="margin-top: 3px") Voltar
    .title(style="margin: 0px") Editar contato
  .flex.h-full.w-full.flex-col.gap-2
    p Carregando informações do usuário...
    .flex
      loading-ring
.content(v-else-if="contact && loaded")
  modal(v-if="displayDeleteContactModal" @click='displayDeleteContactModal = false')
    modal-body
      template(v-slot:header)
        v-heading Confirmação de exclusão
      template(v-slot:body)
        p.text-base.mb-4.inline Deseja remover o contato
        p.text-base.mb-4.font-bold.inline {{ ` ${contact.name}?` }}
        p.text-base Essa ação é irreversível. Não será possível recuperar o contato excluído.
      template(v-slot:footer)
        .flex.justify-center.items-center.gap-2
          v-button-secondary(@click='displayDeleteContactModal=false' :disabled="loadingContactDelete") Cancelar
          app-button(@click='deleteContact(contact.id)', :loading="loadingContactDelete") Confirmar

  .container-fluid
    .pagearea.mt-0.pt-8
      div(style="width: 100%; display: flex; justify-content: center")
        invalid-info(v-if="this.$store.getters['GET_MODAL'] === 'InvalidInfo'")
      .header(style="align-items: flex-end; margin-bottom: 32px")
        .incall-left-aligner(style="flex-direction: column; display: flex")
          div(@click="$router.push({ name: 'Contatos' })" role="button" style="flex-direction: row; display: flex")
            span.arrow-icon.material-icons(style="padding-left: 0px; min-width: 0px; width: 20px") &#xe5c4
            a.back-text(style="margin-top: 3px") Voltar
          .title(style="margin: 0px") Editar contato
        .incall-right-aligner(style="width: 44%")
          app-button(danger, @click="displayDeleteContactModal = true") Excluir contato

      .horizontal-aligner.flex(v-if="contact")
        .profile-aligner.flex.flex-col.items-center.justify-start.gap-2.mt-2(style="width: 55%")
          .profile-box.flex.justify-center.w-48.h-48.bg-brand.bg-opacity-20.rounded-md.cursor-pointer.transition(
            class="hover:bg-opacity-30 active:scale-95"
            @click="showUploadImageModal = true"
          )
            span.material-icons.notranslate.top-user-icon.select-none(
              v-if="imageError && !uploaded"
            ) perm_identity
            img.h-48.w-48.rounded-md(
              v-else-if="!imageError && !uploaded"
              :src="`${this.requestLinks.clist}/uploads/${contact.id}.jpg`"
              @error="imageError = true"
            )
            img.h-48.w-48.rounded-md.cursor-pointer.transition-all(
              v-else-if="uploaded"
              @click="showUploadImageModal = true",
              class="active:scale-90 hover:opacity-50"
              :src="uploadedImage"
            )
          v-button-secondary(
            role="button"
            @click="showUploadImageModal = true"
          ) Adicionar ou alterar foto
          upload-image(v-if="showUploadImageModal", title="Alterar foto do contato", @close="showUploadImageModal = false", @apply-image="handleApplyImage")
        .input-aligner(style="width: 45%" v-if="contact")
          simple-input(ref="contactInput" label="Nome" v-model="contact.name")
          v-dropdown.mt-4(
            placement="bottom-start",
            :triggers="[]",
            :shown="showOrgList",
            :autoHide="false"
            auto-size
          )
            simple-input(
              v-model="selectedOrgName"
              label="Organização"
              @focus="watchOrganization",
              @blur="showOrgList = false"
              :key="contact.organizationId"
              :class="{'!border-red-400': orgError}"
            )
            template(#popper)
              .max-h-72.overflow-auto.divide-y
                li.flex.justify-start.items-center.w-full.h-10.bg-zinc-50(
                  v-for="org in organizationList", 
                  :key="org.id"
                )
                  p.w-full.ml-4.text-gray-400.cursor-pointer(
                    @mousedown="selectOrganization(org)"
                  ) {{ org.name }}
                loading-ring(v-if="loadingOrganizations")
                observer.py-2(@intersect="loadMoreOrganizations", v-else-if="hasMoreOrganizations")
                li.flex.justify-start.items-center.w-full.h-10.bg-zinc-50(
                  v-if="!organizationList?.length"
                )
                  p.w-full.ml-4.text-brand-500.cursor-pointer(
                    @mousedown="addNewOrganization"
                  )
                    span.font-semibold(class="dark:invert") Adicionar nova organização

          simple-input.mt-4(
            label="CPF",
            v-model="contact.cpf",
            type="text",
            :caract="14"
          )
          .flex.mt-4.flex-col.gap-2
            .flex.w-full.gap-2.flex-col
              simple-input.w-full(
                v-for="(email, index) in contact.emails"
                v-model="email.address"
                label="E-mail"
                :key="email.id"
              )
            .flex.w-full.justify-end
              a.font-bold.font-inter.text-xs.text-center.text-brand.tracking-widest.w-auto(
                class="hover:text-opacity-60"
                role="button",
                v-on:click="appendEmailInput()",
              ) Adicionar email
          v-dropdown.mt-4(
            placement="bottom-start",
            :triggers="[]",
            :shown="showTagList",
            :autoHide="false"
            auto-size
          )
            simple-input(
              v-model="selectedCategoryName"
              label="Categoria"
              @focus="showTagList = true",
              @blur="showTagList = false"
              :key="contact.categoryId"
              auto-size
            )
            template(#popper)
              .max-h-72.overflow-auto.divide-y
                li.flex.justify-start.items-center.h-10.bg-zinc-50.cursor-pointer(
                  v-for="category in computedCategories",
                  :key="category.id"
                )
                  p.ml-4.text-gray-400.w-full(
                    @mousedown="selectCategory(category)"
                  ) {{ category.name }}

                li.flex.justify-start.items-center.w-full.h-10.bg-zinc-50(
                  v-if="!computedCategories.length"
                )
                  p.ml-4.text-brand-400.cursor-pointer(
                    @mousedown="displayNewCategoryModal=true"
                  )
                    span.font-semibold(class="dark:invert") Adicionar nova categoria

          modal(v-if="displayNewCategoryModal" @click='displayNewCategoryModal=false')
            modal-body
              template(v-slot:header)
                v-heading Criar categoria

              template(v-slot:body)
                p.text-sm.text-zinc-400.mb-4 Preencha os campos a baixo para criar uma categoria
                simple-input.w-96(label='Nova categoria' v-model="newCategoryName")

              template(v-slot:footer)
                .flex.justify-center.items-center
                  v-button.mr-2(@click='addNewCategory') Criar
                  v-button-secondary(@click='displayNewCategoryModal=false') cancelar

          #number-div(v-if="contact.phones")
            label.up-placeholder Telefone principal
            .flex.gap-4.w-full
              v-dropdown.w-14.h-full(placement="bottom")
                button(class="group flex-1 h-11 hover:bg-brand hover:bg-opacity-5 active:scale-95 active:bg-opacity-10 flex items-center px-3 py-1 pr-1 mr-1 transition border rounded-md cursor-pointer select-none")
                  .text-xs.font-semibold.font-plus.text-zinc-500(class="group-active:text-brand") +{{ contact.phones[0].ddi }}
                  span(class="material-icons notranslate group-hover:text-brand text-gray-400") keyboard_arrow_down
                template(#popper)
                  ul(class="w-full max-h-40 gap-2 flex flex-col p-1 divide-y justify-start")
                    .flex.h-10.w-full.mt-1
                      icon-input(:label="'DDI'" v-model="ddiSearch")
                    dropdown-item(
                      v-for="(ddi, index) in avaliableDdis.filter((ddi) => ddi.number.includes(ddiSearch))",
                      @click="contact.phones[0].ddi = ddi.number", 
                      :label="`${ddi.flag} +${ddi.number}`",
                      :key="index",
                      :hide-icon="true" 
                      :selected="contact.phones[0].ddi === ddi.number"
                    )
                    loading-ring(v-if="loadingCountries")
              input-phone.flex-1(
                label="Número de telefone principal" 
                v-model="contact.phones[0].number"   
              )
            label.up-placeholder(v-if="contact.phones.length > 1") Outros números
            .input-icons(v-for="(number, index) in contact.phones.slice(1, contact.phones.length)" :key="`${index}-phone-number`" style="justify-content: flex-end; margin-bottom: 16px")
              .flex.gap-4.w-full
                v-dropdown.w-14.h-full(placement="bottom")
                  button(class="group flex-1 h-11 hover:bg-brand hover:bg-opacity-5 active:scale-95 active:bg-opacity-10 flex items-center px-3 py-1 pr-1 mr-1 transition border rounded-md cursor-pointer select-none")
                    .text-xs.font-semibold.font-plus.text-zinc-500(class="group-active:text-brand") +{{ number.ddi }}
                    span(class="material-icons notranslate group-hover:text-brand text-gray-400") keyboard_arrow_down
                  template(#popper)
                    ul(class="w-full max-h-40 gap-2 flex flex-col p-1 divide-y justify-start")
                      .flex.h-10.w-full.mt-1
                        icon-input(:label="'DDI'" v-model="ddiSearch")
                      dropdown-item(
                        v-for="(ddi, index) in avaliableDdis.filter((ddi) => ddi.number.includes(ddiSearch))",
                        @click="number.ddi = ddi.number", 
                        :label="`${ddi.flag} +${ddi.number}`",
                        :key="`ddi-${ddi.number+index+1}`",
                        :hide-icon="true" 
                        :selected="number.ddi === ddi.number"
                      )
                input-phone.flex-1(
                  label="Número de telefone"
                  :index="index + 1", 
                  v-model="number.number"
                )
              i.material-icons.notranslate.closeicon-input(v-if="index !== -1" v-on:click="displayDeleteModal=true" style="margin-right: 14px") &#xe5cd
              modal(v-if="displayDeleteModal" @click='displayDeleteModal=false')
                modal-body
                  template(v-slot:header)
                    v-heading Confirmação de exclusão
                  template(v-slot:body)
                    p.text-sm.text-zinc-400.mb-4 Deseja excluir este número?
                    p.text-sm.text-zinc-400.mb-4 Essa ação é irreversível. Não será possível recuperar o número excluído.
                  template(v-slot:footer)
                    .flex.justify-center.items-center.gap-4
                      v-button-secondary(@click='displayDeleteModal=false') Cancelar
                      v-button(@click='deletePhone(number, index + 1)') Excluir
          .flex.w-full.mt-4.justify-end
            a.font-bold.font-inter.text-xs.text-center.text-brand.tracking-widest.w-auto(
              class="hover:text-opacity-60"
              role="button",
              v-on:click="appendNumberInput()"
            ) Adicionar número

          .flex.gap-2.mt-2.justify-end
            v-button-secondary(@click="$router.push({ name: 'Contatos' })") Cancelar
            app-button(@click="saveChanges", :loading="loadingSaveChanges") Salvar
</template>

<script>
import { parsePhoneNumber } from '@/utils/google-libphonenumber.util';
import { Dropdown } from 'floating-vue';
import DropdownItem from '@/components/Dropdown/dropdown-item.vue';
import cListService, { getCategoryList } from '@/services/contact-list.js';
import InvalidInfo from '@/components/Modals/InvalidInfo.vue';
import OrganizationInput from './Contact.DropdownInput';
import CategoryInput from './Category.DropdownInput';
import PhoneInput from './Contact.PhoneInput';
import VuePhoneNumberInput from 'vue-phone-number-input';
import ContactPhoneInput from './Contact.PhoneInput.vue';
import VButton from '@/components/Buttons/v-button.vue';
import SimpleInput from '@/components/Inputs/SimpleInput.vue';
import Modal from '@/components/Modals/Modal.vue';
import ModalBody from '@/components/Modals/ModalBody.vue';
import VButtonSecondary from '@/components/Buttons/v-button-secondary.vue';
import VHeading from '@/components/Text/v-heading.vue';
import InputPhone from '@/components/Inputs/InputPhone.vue';
import '../../assets/css/vue-phone-number-input.css';
import LoadingRing from '@/components/LoadingRing.vue';
import IconInput from '@/components/Inputs/IconInput.vue';
import UploadImage from '@/components/Modals/UploadImage.vue';
import AppButton from '@/components/Buttons/app-button.vue';
import Observer from '@/components/Observer.vue';
import { debounce } from '@/helpers';

export default {
  title: 'Opens - Editar contato',
  components: {
    Modal,
    ModalBody,
    VButton,
    VButtonSecondary,
    OrganizationInput,
    CategoryInput,
    AppButton,
    VuePhoneNumberInput,
    InvalidInfo,
    PhoneInput,
    ContactPhoneInput,
    VDropdown: Dropdown,
    DropdownItem,
    VHeading,
    UploadImage,
    SimpleInput,
    InputPhone,
    LoadingRing,
    IconInput,
    Observer,
  },
  data() {
    return {
      loadingContactDelete: false,
      displayDeleteContactModal: false,
      imageError: false,
      loadingOrganizations: false,
      loadingCountries: false,
      loadingSaveChanges: false,
      hasMoreOrganizations: true,
      organizationOffset: 0,
      showUploadImageModal: false,
      loaded: false,
      uploadedImage: null,
      selectedCategoryName: '',
      displayNewCategoryModal: false,
      newCategoryName: '',
      showOrgList: false,
      contact: {},
      displayDeleteModal: false,
      categories: [],
      updatedPhones: {},
      orgList: [],
      file: null,
      organizationList: [],
      inputPayload: {},
      uploaded: false,
      loaded: false,
      currentModal: null,
      index: null,
      payload: {},
      orgError: false,
      inputValue: [],
      selected: false,
      showTagList: false,
      selectedOrgName: '',
      clist: process.env.VUE_APP_CLIST,
      avaliableDdis: [{ number: '55', flag: 'BR' }],
      ddiSearch: '',
    };
  },
  methods: {
    appendEmailInput() {
      this.contact.emails.push({ address: '' });
    },
    async fetchCountries() {
      this.loadingCountries = true;
      try {
        const data = await cListService.getCountries();

        this.avaliableDdis = data;
      } catch (e) {
        console.error(e);
        this.$toast.error("Erro ao buscar DDI's");
      } finally {
        this.loadingCountries = false;
      }
    },
    formatCpfNumber(value) {
      if (!value) return '';
      let cpf = value?.replace(/\D/g, '')?.slice(0, 11);
      if (!cpf?.length) return '';
      let formattedCPF = '';

      for (let i = 0; i < cpf.length; i++) {
        if (i === 3 || i === 6) {
          formattedCPF += '.';
        } else if (i === 9) {
          formattedCPF += '-';
        }
        formattedCPF += cpf[i];
      }

      return formattedCPF;
    },
    test(number, index) {
      this.$log.info(number.country + ' ' + index);
      return this.contact.phones[index].country;
    },
    loadComponent(ddi, index) {
      setTimeout(() => {
        document.getElementsByClassName('country-selector__label').item(index).innerHTML = '';
        document.getElementsByClassName('input-tel__input').item(index).placeholder = '';
        document.getElementsByClassName('input-tel__label').item(index).style.display = 'none';
      }, 0);
      if (ddi == null) {
        return 'BR';
      }
      return ddi;
    },
    orgExists(org) {
      const orgs = this.organizationList.filter((organization) => org == organization.name);

      return orgs.length > 0;
    },
    selectOrganization(e) {
      this.selected = true;
      this.contact.organizationId = e.id;
      this.selectedOrgName = e.name;
      this.showOrgList = false;
    },
    selectCategory(category) {
      this.selectedCategoryName = category.name;
      this.contact.categoryId = category.id;
      this.showTagList = false;
    },
    resetOrg() {
      this.selected = false;
      this.selectedOrgName = '';
      this.contact.organizationId = undefined;
    },
    async deleteContact(contactId) {
      try {
        this.loadingContactDelete = true;
        this.$toast.info('Removendo contato...');

        await cListService.destroyContact(contactId);
        this.$router.push('/contacts');
        this.$toast.success('Contato removido');
      } catch (error) {
        this.$toast.error('Não foi possível remover o contato...');
      } finally {
        this.loadingContactDelete = false;
      }
    },
    appendNumberInput() {
      this.contact.phones.push({ number: '', ddi: '55', contactId: this.contact.id, companyId: this.user.company_id });
    },
    async saveChanges() {
      if (!this.contact.name) return this.$toast.error('Nome não pode estar vazio');
      this.loadingSaveChanges = true;
      try {
        if (this.contact.phones[0].number?.length) {
          for (const phone of this.contact.phones) {
            if (phone?.ddi == '55' && phone?.ddi) {
              const { ddiParsed, isValidNumber, phoneNumberParsed } = parsePhoneNumber(phone.number, phone.ddi);

              if (!isValidNumber) {
                return this.$toast.error('Número de telefone inválido!');
              }

              phone.number = phoneNumberParsed
                .replace(/ /g, '')
                .replace(/\(/g, '')
                .replace(/\)/g, '')
                .replace(/-/g, '');
              phone.ddi = ddiParsed;
            } else {
              phone.number = phone.number.replace(/ /g, '').replace(/\(/g, '').replace(/\)/g, '').replace(/-/g, '');
            }
            phone.country = this.avaliableDdis.filter((ddi) => ddi.number == phone.ddi)[0]?.flag || 'BR';
            phone.companyId = this.user.company_id;
          }
        } else {
          delete this.contact.phones;
        }

        this.contact.cpf = this.contact?.cpf?.replace(/\D/g, '') || null;
        if (this?.contact?.cpf) {
          if (this.contact?.cpf?.length < 11) {
            return this.$toast.error('CPF inválido');
          }
        }

        await cListService.editContact(this.contact.id, this.contact);

        if (this.file) await cListService.uploadContactAvatar(this.contact.id, this.file);

        this.$toast.success('Contato salvo com sucesso!');
        this.$router.push({ name: 'Contatos' });
      } catch (error) {
        console.error(error);
        this.$toast.error(error?.response?.data?.message || 'Ocorreu um erro ao salvar o contato');
      } finally {
        this.loadingSaveChanges = false;
      }
    },
    async deletePhone(phone, index) {
      try {
        if (phone.id) await cListService.deletePhoneById(phone.id);
        this.contact.phones.splice(index, 1);
        this.$toast.success('Telefone removido');
        this.displayDeleteModal = false;
      } catch (error) {
        this.$toast.error('Não foi possível remover o telefone...');
      }
    },
    async addNewCategory() {
      try {
        this.selectedCategoryName = '';
        const category = await cListService.createCategory({
          name: this.newCategoryName,
          companyId: this.user.company_id,
        });
        this.categories = await getCategoryList();
        this.$toast.success('Categoria criada com sucesso!');
        this.selectedCategoryName = category.name;
        this.contact.categoryId = category.id;
        this.displayNewCategoryModal = false;
      } catch (error) {
        this.$toast.error('Opa, não conseguimos criar essa categoria...');
      }
    },
    updatePhone(phone, index) {
      this.updatedPhones[index] = {
        id: phone.id,
        number: phone.number,
      };
    },
    async handleApplyImage(data) {
      this.uploaded = true;
      this.imageError = false;
      this.file = data.blobImage;
      this.uploadedImage = URL.createObjectURL(data.blobImage);
      this.showUploadImageModal = false;
    },
    watchOrganization() {
      this.showOrgList = true;
    },
    addNewOrganization() {
      this.selectedOrgName = '';
      this.$store.commit('modal/setActiveModal', 'CreateOrganization');
    },
    async loadOrganizations() {
      if (!this.hasMoreOrganizations || this.loadingOrganizations) return;
      this.loadingOrganizations = true;
      try {
        const payload = {
          offset: this.organizationOffset,
          limit: 10,
          companyId: this.user.company_id,
        };

        if (this.selectedOrgName) payload.searchName = this.selectedOrgName;

        const data = await cListService.getOrganizationList(payload);
        this.organizationList = [...this.organizationList, ...data];
        if (data.length < 10) this.hasMoreOrganizations = false;
      } catch (error) {
        this.$toast.error('Opa, não conseguimos carregar as organizações...');
      } finally {
        this.loadingOrganizations = false;
      }
    },
    searchOrganization: debounce(function () {
      this.hasMoreOrganizations = true;
      this.organizationOffset = 0;
      this.organizationList = [];
      this.loadOrganizations();
    }, 500),
    loadMoreOrganizations() {
      this.organizationOffset += 10;
      this.loadOrganizations();
    },
  },
  computed: {
    modal() {
      return this.$store.getters['modal/activeModal'];
    },
    computedCategories() {
      return this.categories.filter((category) =>
        category.name.toLowerCase().startsWith(this.selectedCategoryName?.toLowerCase()),
      );
    },
  },
  watch: {
    async modal(value) {
      if (!value) {
        this.organizationList = await cListService.getOrganizationList();
      }
    },
    'contact.cpf'(val) {
      this.$nextTick(() => {
        this.contact.cpf = this.formatCpfNumber(val);
      });
    },
    selectedOrgName(val) {
      this.searchOrganization();
      if (!val) {
        this.contact.organizationId = null;
      }
    },
    selectedCategoryName(val) {
      if (!val) {
        this.contact.categoryId = null;
      }
    },
  },
  async created() {
    await this.fetchCountries();
    try {
      this.categories = await getCategoryList();

      const contact = await cListService.getContactById(this.$route.params.id);

      if (contact.emails.length === 0) contact.emails.push({ email: '', contactId: contact.id });

      this.contact = contact;

      if (this.contact.category) this.selectedCategoryName = contact.category.name;

      if (this.contact.organization) this.selectedOrgName = contact.organization.name;

      if (this.contact?.cpf) {
        this.contact.cpf = this.formatCpfNumber(this.contact.cpf);
      }
      if (!this.contact.phones[0]) this.appendNumberInput();

      delete this.contact.organization;
      delete this.contact.category;

      for (let i = 0; i < this.contact.phones.length; i++) {
        this.inputValue[i] = this.contact.phones[i].number;
      }

      await this.loadOrganizations();
      this.loaded = true;
    } catch (e) {}
  },
};
</script>
<style>
.remove-contact-modal {
  position: absolute;
  display: none;
  box-shadow: 0px 0px 10000px 10000px rgba(0, 0, 0, 0.8);
  z-index: 9999;
  width: 610px;
  height: 285px;
  background: #ffffff;
  padding: 48px;
}

.delete-button {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
  font-size: 14px;
  color: var(--brand-color) !important;
}

.closeicon-input {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow: none;
  padding-top: 0px;
  padding-bottom: 1px;
  padding-right: 0px;
  cursor: pointer;
  color: #fff !important;
  font-size: 14px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #adb5bd !important;
  transition: 0.2s;
}

.closeicon-input:hover {
  background: #8f9295 !important;
}
</style>
