<template lang="pug">
.w-full.content.font-inter#contentId
  confirm-modal(v-if="this.$store.getters['GET_MODAL'] === 'DeviceConfirmModal'" modalType="DisableConfirmModal")
  confirm-modal(v-if="this.$store.getters['GET_MODAL'] === 'EnableConfirmModal'" modalType="EnableConfirmModal")

  add-device-step1(
    v-if="currentModal === 'stepDevice1Id'" 
    id="stepDevice1Id"
    @close="dismissFunction"
    @openSuccessModal="openSteps('stepDeviceSuccessId')"
    @openFailedModal="openSteps('stepDeviceFailedId')"
  )
  add-device-step2(
    v-if="currentModal === 'stepDevice2Id'"
    id="stepDevice2Id" 
    @close="dismissFunction"
    @openSuccess="openSteps('stepDeviceSuccessId')"
  )
  add-device-success(
    v-if="currentModal === 'stepDeviceSuccessId'"
    id="stepDeviceSuccessId"
    @closeDown="dismissFunction()"
  )
  add-device-failed(
    v-if="currentModal === 'stepDeviceFailedId'"
    id="stepDeviceFailedId"
    @closeDown="dismissFunction"
    @openStepDevice1="openSteps('stepDevice1Id')"
  )

  link(href="https://fonts.googleapis.com/css2?family=Inter&display=swap" rel="stylesheet")
  link(href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet")
  link(href="https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap" rel="stylesheet")
  
  .h-full.container-fluid
    .flex.items-start.justify-start.h-full
      a.mt-0.bg-zinc-100.pref-side-navigator.relative.rounded-tl-3xl.pl-4.w-56.pt-6.min-w-fit
        h1.flex.items-center.justify-start.gap-1.text-xl.font-bold.text-gray-700.font-titilium Preferências
        a.w-full.p-3.mt-6.text-sm.font-bold.text-gray-700.bg-white.border-r-4.rounded-md.rounded-r-none.p-side-nav-item.current.font-plus.border-brand.text-marker(
          href="#/preferences/devices"
          role="button"
          class="dark:invert dark:bg-zinc-900"
        ) Gerenciar ramais
        a.w-full.p-3.mt-2.text-sm.text-gray-700.rounded-md.rounded-r-none.p-side-nav-item.font-plus.text-marker(
          href="#/preferences/integrations"
          role="button"
          class="dark:invert dark:bg-zinc-900 hover:bg-white"
        ) Integrações
      
      .flex.items-start.justify-start.w-full.m-0.page-area
        div.absolute(class="w-3/4")
          failed-modal(
            v-if="this.$store.getters['GET_MODAL'] === 'DisableDeviceFailed'"
            modalText="Falha ao desabilitar device."
          )
          success-modal(
            v-if="this.$store.getters['GET_MODAL'] === 'DisableDeviceSuccess'"
            modalText="Device desabilitado com sucesso."
          )
          failed-modal(
            v-if="this.$store.getters['GET_MODAL'] === 'EnableDeviceFailed'"
            modalText="Falha ao habilitar device."
          )
          success-modal(
            v-if="this.$store.getters['GET_MODAL'] === 'EnableDeviceSuccess'"
            modalText="Device habilitado com sucesso."
          )

        .header
          .titles-area
            h1.title Gerenciar ramais
            h2.subtitle Ramais SIP
            .text-sm.subtitle-info-text.text-zinc-400 Conecte um ramal para começar a utilizar as funções de ligação.
        
        .w-full.card.mt-14
          .card-body
            table.table.table-striped
              thead.thread-snep7
                tr.text-left
                  th.padding-left Device
                  th Status
                  th Web
                  th
              tbody
                device(
                  v-for="(device, key) in devices.sip"
                  :key="key"
                  :device="device"
                  v-on:deleteDevice="deleteDevice"
                )
            div
              .no-device.w-full(v-if="!devices.sip.length")
                span.info-orange-icon.notranslate.material-icons error_outline
                | Você ainda não possui nenhum Ramal SIP vinculado.
        
        .flex.flex-col.gap-6.items-start.w-full.mb-12(v-if="hasPermissionToAccessDialerOptions")
          .flex.flex-col.gap-2.items-start
            h1.text-2xl.font-semibold Configurações do discador
            p.text-sm.subtitle-info-text.text-zinc-400 Controle aqui se você deseja utilizar antigo ou o novo discador.
          dropdown(
            label="Qual discador você deseja utilizar?",
            :placeholder="showNewDialer ? 'Discador novo' : 'Discador antigo'"
          )
            dropdown-item-v2(
              :selected="showNewDialer",
              text="Discador novo",
              @click="setNewDialer",
              v-close-popper
            )
            dropdown-item-v2(
              :selected="!showNewDialer",
              @click="goBackToOldDialerModal = true",
              text="Discador antigo",
              v-close-popper
            )

          modal(v-if="goBackToOldDialerModal", @click="goBackToOldDialerModal = false")
            modal-body(class="w-[30rem]")
              template(#header)
                .flex.w-full.justify-between.items-center
                  h4.text-2xl.text-gray-600.font-semibold.font-plus Voltar ao antigo discador
                  .material-icons.notranslate.cursor-pointer.text-gray-500(
                    class="hover:text-red-500",
                    @click="openActivateRDModal = false",
                    v-tooltip="'Fechar'"
                  ) close
              template(#body)
                .flex.flex-col.gap-2.items-start
                  p.font-plus Tem certeza que deseja voltar ao antigo discador?
                  dropdown.w-full(
                    label="Diga para nós o que fez você voltar ao antigo discador"
                    :placeholder="goBackToOldDialerReason || 'Selecione uma opção'"
                  )
                    dropdown-item-v2(
                      @click="goBackToOldDialerReason = 'Não me adaptei'",
                      :selected="goBackToOldDialerReason === 'Não me adaptei'",
                      text="Não me adaptei",
                      v-close-popper
                    )
                    dropdown-item-v2(
                      @click="goBackToOldDialerReason = 'Não atendeu as minhas expectativas'",
                      :selected="goBackToOldDialerReason === 'Não atendeu as minhas expectativas'",
                      text="Não atendeu as minhas expectativas",
                      v-close-popper
                    )
                    dropdown-item-v2(
                      @click="goBackToOldDialerReason = 'Não funciona como esperado'",
                      :selected="goBackToOldDialerReason === 'Não funciona como esperado'",
                      text="Não funciona como esperado",
                      v-close-popper
                    )
                    dropdown-item-v2(
                      @click="goBackToOldDialerReason = 'Outro'",
                      :selected="goBackToOldDialerReason === 'Outro'",
                      text="Outro",
                      v-close-popper
                    )
                  textarea.w-full.border.rounded-lg.p-2.font-plus(
                    class="min-h-[10rem] max-h-96 focus:border-brand",
                    placeholder="Eu decidi voltar ao antigo discador porque...",
                    v-model="goBackToOldDialerText"
                  )
              template(#footer)
                .flex.justify-center.items-center.gap-2
                  v-button-secondary(
                    @click="goBackToOldDialerModal = false"
                  ) Cancelar
                  app-button(
                    :disabled="!goBackToOldDialerReason",
                    @click="goBackToOldDialer"
                  ) Confirmar

        .w-full
          h1.mb-3.text-2xl.font-semibold Ajuste de audio
          p.mb-12.text-sm.subtitle-info-text.text-zinc-400 Controle aqui o volume dos audios do sistema, como chamadas e notificações
          .flex.items-center.justify-start
            button.w-6.h-6.mr-2.text-white.rounded-full.cursor-pointer.material-icons.bg-zinc-300(
              @click="decreaseAudioVolume(0.1)"
            ) &#xe15b
            .flex.justify-between.h-6.w-52
              .h-full.overflow-hidden.rounded.cursor-pointer.bg-zinc-200(
                class="w-1/12"
                v-for="item in 10"
                :key="item"
                @click="setAudioVolume(item / 10)"
              )
                .w-full.h-full.bg-brand-400(
                  class="dark:invert"
                  v-show="(item / 10) <= audioVolume"
                )
            button.w-6.h-6.ml-2.text-white.rounded-full.cursor-pointer.material-icons.bg-zinc-300(
              @click="increaseAudioVolume(0.1)"
            ) &#xe145
        
        .w-full.mt-14
          h1.mb-3.text-2xl.font-semibold Som de Chamada
          p.mb-12.text-sm.subtitle-info-text.text-zinc-400 Selecione o som que deseja ouvir quando receber ou realizar uma chamada
          .flex.flex-col.justify-start.gap-2
            .flex.items-center.justify-start.w-full.gap-4.p-4.bg-white.rounded-xl(
              v-for="(ringingSound, index) of avaliablesRingingSounds"
              :key="`ringing-sound-${index}`"
            )
              .flex.items-center.justify-start.flex-1.gap-2
                span.material-icons.notranslate.text-brand-400 music_note
                p.text-sm.font-semibold {{ ringingSound.title }}
              .flex.items-center.justify-center.gap-4.select-none
                .grid.w-8.h-8.text-white.transition-colors.rounded-full.cursor-pointer.place-items-center.material-icons.notranslate.bg-zinc-300(
                  class="hover:bg-zinc-400"
                  @click="() => handleTogglePlayRingingSound(index)"
                  title="Ouvir som de chamada"
                ) {{ ringingSound.isPlaying ? 'pause' : 'play_arrow' }}
                .grid.w-8.h-8.text-white.transition-colors.rounded-full.cursor-pointer.place-items-center.material-icons.notranslate(
                  @click="() => toggleRingingDefaultSound(ringingSound.isDefault, index)"
                  :class="`${ ringingSound.isDefault ? 'bg-brand-400 hover:bg-brand-600': 'bg-zinc-300 hover:bg-zinc-400' }`"
                  title="Selecionar este som de chamada"
                ) check
        
        section.mt-14
          h2.subtitle Notificação de Inatividade
          .flex.items-center.justify-start.gap-4
            p.text-sm.subtitle-info-text.text-zinc-400 Receber notificações quando ficar inativo por um tempo:
            switch-box.m-0(v-model="showInactivityNotification")
        
        section.mt-14
          h2.subtitle Notificação de Mensagens
          .flex.items-center.justify-start.gap-4
            p.text-sm.subtitle-info-text.text-zinc-400 Receber notificações quando receber uma mensagem:
            switch-box.m-0(v-model="showMessagesNotification")
</template>

<script>
import AddDeviceStep1 from './AddDevice/AddDeviceStep1.vue';
import AddDeviceStep2 from './AddDevice/AddDeviceStep2.vue';
import AddDeviceFailed from './AddDevice/AddDeviceFailed.vue';
import AddDeviceSuccess from './AddDevice/AddDeviceSuccess.vue';
import ConfirmModal from './Devices.ConfirmModal.vue';
import Device from './Devices.device';
import SideNav from './PrefSideNav.vue';
import ProgressModal from '@/components/Modals/ProgressModal.vue';
import FailedModal from '@/components/Modals/FailedModal.vue';
import SuccessModal from '@/components/Modals/SuccessModal.vue';
import SwitchBox from '@/components/Inputs/SwitchCheckbox.vue';
import { updateUserRingingSound, getUserRingingSound } from '@/services/cservice';
import Dropdown from '@/components/Dropdown/dropdown.vue';
import DropdownItemV2 from '@/components/Dropdown/dropdown-item-v2.vue';
import Modal from '@/components/Modals/Modal.vue';
import ModalBody from '@/components/Modals/ModalBody.vue';
import AppButton from '@/components/Buttons/app-button.vue';
import VButtonSecondary from '@/components/Buttons/v-button-secondary.vue';
import EventBus from '@/main-event-bus';

export default {
  title: 'Opens - Preferências',
  components: {
    SideNav,
    Device,
    AddDeviceStep1,
    AddDeviceStep2,
    AddDeviceFailed,
    AddDeviceSuccess,
    ConfirmModal,
    ProgressModal,
    FailedModal,
    SuccessModal,
    SwitchBox,
    Dropdown,
    DropdownItemV2,
    Modal,
    ModalBody,
    AppButton,
    VButtonSecondary,
  },
  data() {
    return {
      mobileCode: '',
      currentModal: '',
      goBackToOldDialerModal: false,
      goBackToOldDialerReason: '',
      goBackToOldDialerText: '',
      actualDevice: {},
      inactivityTime: 20,
      showNewDialer: false,
      showInactivityNotification: true,
      showMessagesNotification: true,
      avaliablesRingingSounds: [
        {
          title: 'Padrão',
          name: 'default',
          isPlaying: false,
          isDefault: true,
        },
        {
          title: 'Padrão 2',
          name: 'ringing2',
          isPlaying: false,
          isDefault: false,
        },
        {
          title: 'Celular Tocando',
          name: 'cellphone_ringing',
          isPlaying: false,
          isDefault: false,
        },
        {
          title: 'Telefone de Escritório Tocando',
          name: 'office_ringing',
          isPlaying: false,
          isDefault: false,
        },
        {
          title: 'Telefone Digital Tocando',
          name: 'digitalphone_ringing',
          isPlaying: false,
          isDefault: false,
        },
        {
          title: 'Telefone Tocando',
          name: 'ring_ringing',
          isPlaying: false,
          isDefault: false,
        },
      ],
      newDialerListedCompanies: process.env.VUE_APP_NEW_DIALER_WHITE_LIST,
    };
  },
  computed: {
    hasPermissionToAccessDialerOptions() {
      if (!this.newDialerListedCompanies) return false;
      if (this.newDialerListedCompanies?.split(',').includes(this.user.company_id)) return true;
    },
    audioVolume() {
      return this.$store.getters['userPreferences/getAudioVolume'];
    },
  },
  watch: {
    inactivityTime() {
      localStorage.setItem('@yunique:inactivity-time', this.inactivityTime);
    },
    showNewDialer() {
      localStorage.setItem('@yunique:use-new-dialer', this.showNewDialer);
    },
    showInactivityNotification() {
      localStorage.setItem('@yunique:show-inactivity-notification', this.showInactivityNotification);
    },
    showMessagesNotification() {
      localStorage.setItem('@yunique:show-messages-notification', this.showMessagesNotification);
    },
  },
  methods: {
    setNewDialer() {
      window.location.reload();
      this.showNewDialer = true;
    },
    goBackToOldDialer() {
      EventBus.$emit('mixpanel-event', {
        eventName: 'go-back-to-old-dialer',
        payload: {
          reason: this.goBackToOldDialerReason,
          text: this.goBackToOldDialerText,
        },
      });
      this.goBackToOldDialerModal = false;
      this.showNewDialer = false;
      window.location.reload();
    },
    async openSteps(stepId) {
      this.currentModal = stepId;
    },
    dismissFunction() {
      this.currentModal = '';
    },
    proceed(code) {
      this.mobileCode = code;
      this.openSteps('stepRamal2Id');
    },
    listenDeviceCode(code) {
      this.deviceCode = code;
    },
    async dismissFunctionAndReload() {
      this.currentModal = '';
      try {
        await this.$store.dispatch('user/requestDevices', this.$http);
      } catch (e) {
        this.$log.info(e);
      }
    },
    async deleteDevice(deviceId) {
      try {
        await this.$http.delete(`${this.requestLinks.cservice}/companies/users/devices/${deviceId}`);
        this.$router.go(this.$router.currentRoute);
      } catch (e) {}
    },

    async deleteMobileDevice(deviceId) {
      try {
        await this.$http.delete(`${this.requestLinks.cservice}/companies/users/devices/${deviceId}`);
        this.$router.go(this.$router.currentRoute);
      } catch (e) {}
    },
    increaseAudioVolume(value) {
      const volume = Math.min(1, this.audioVolume + value);
      this.setAudioVolume(volume);
    },
    decreaseAudioVolume(value) {
      const volume = Math.max(0, this.audioVolume - value);
      this.setAudioVolume(volume);
    },
    setAudioVolume(value) {
      this.$store.commit('userPreferences/setAudioVolume', value);
    },
    async toggleRingingDefaultSound(isDefault, index) {
      if (isDefault) return;
      this.avaliablesRingingSounds.forEach((ringingSound, i) => {
        if (i === index) ringingSound.isDefault = true;
        else ringingSound.isDefault = false;
      });

      try {
        const ringingSoundName = this.avaliablesRingingSounds[index].name;
        await updateUserRingingSound(ringingSoundName, this.user.id);
        localStorage.setItem(`@yunique:ringing-sound:${this.user.id}`, ringingSoundName);
        this.$toast.success('Som de chamada padrão alterado com sucesso');
      } catch (e) {
        this.$log.error(e);
        this.$toast.error('Não foi possível alterar o som de chamada padrão');
      }
    },
    handleTogglePlayRingingSound(index) {
      const ringingSound = this.avaliablesRingingSounds[index];
      const ringingSounds = this.$store.getters['cstate/ringingSounds'];
      this.avaliablesRingingSounds.forEach((ringingSound) => {
        ringingSound.isPlaying = false;
        ringingSounds[ringingSound.name]?.pause();
      });
      const selectedRingingSound = ringingSounds[ringingSound.name] || ringingSounds['default'];

      selectedRingingSound.addEventListener('ended', () => {
        ringingSound.isPlaying = false;
      });

      const isAudioPlaying =
        selectedRingingSound.paused && !selectedRingingSound.ended && selectedRingingSound.currentTime > 0;

      if (isAudioPlaying) {
        selectedRingingSound.pause();
        selectedRingingSound.currentTime = 0;
        ringingSound.isPlaying = false;
      } else {
        selectedRingingSound.volume = this.$store.getters['userPreferences/getAudioVolume'];
        selectedRingingSound.play();
        ringingSound.isPlaying = true;
      }
    },
  },
  async created() {
    const showNewDialer = localStorage.getItem('@yunique:use-new-dialer');
    const showInactivityNotification = localStorage.getItem('@yunique:show-inactivity-notification');
    const showMessagesNotification = localStorage.getItem('@yunique:show-messages-notification');

    if (showNewDialer === null) localStorage.setItem('@yunique:use-new-dialer', 'false');
    else this.showNewDialer = showNewDialer === 'true';

    if (showInactivityNotification === null) localStorage.setItem('@yunique:show-inactivity-notification', 'true');
    else this.showInactivityNotification = showInactivityNotification === 'true';

    if (showMessagesNotification === null) localStorage.setItem('@yunique:show-messages-notification', 'true');
    else this.showMessagesNotification = showMessagesNotification === 'true';

    this.inactivityTime = +localStorage.getItem('@yunique:inactivity-time') || 20;

    try {
      const data = await getUserRingingSound(this.user.id);
      this.avaliablesRingingSounds.forEach((ringingSound) => {
        if (data.ringing_sound && ringingSound.name === data.ringing_sound) ringingSound.isDefault = true;
        else if (!data.ringing_sound && ringingSound.name === 'default') ringingSound.isDefault = true;
        else ringingSound.isDefault = false;
      });
    } catch (e) {
      this.$log.error(e);
    }
  },
};
</script>

<style>
.info-orange-icon {
  color: var(--brand-color);
  margin-right: 10px;
}

.no-device {
  padding-top: 26px;
  width: 130%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: 'Inter', sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #868e96;
}

.content {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.disable-button {
  margin-bottom: 7px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 14px;
  transition: 0.2s !important;
  color: var(--brand-color) !important;
}

.disable-button:hover {
  transform: scale(1.05);
}

.padding-left {
  padding-left: 30px !important;
}

.close-icon {
  width: 26px;
  height: 26px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #adb5bd;
  border-radius: 13px;
  padding-top: 0px;
  font-size: 20px !important;
}

.close-icon:hover {
  width: 26px;
  height: 26px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #8e949b;
  border-radius: 13px;
  padding-top: 0px;
  font-size: 20px !important;
  transition: 0.1s;
}

.phone-icon {
  display: inline-flex;
  align-items: flex-end;
  vertical-align: bottom;
  justify-content: center;
  width: 32px;
  color: var(--brand-color);
  font-size: 24px !important;
  margin-right: 15px;
  height: 32px;
  line-height: 30px;
  background: var(--brand-color-op-1);
  border-radius: 4px;
}

.mobile-icon {
  display: inline-flex;
  align-items: flex-end;
  vertical-align: bottom;
  justify-content: center;
  width: 32px;
  color: var(--brand-color);
  font-size: 31.5px !important;
  margin-right: 15px;
  height: 32px;
  background: var(--brand-color-op-1);
  border-radius: 4px;
}
</style>
