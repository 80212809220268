<template lang="pug">
.user-contact-box(v-if="!contact.access_token")
  .upside-contact-box
    .flex(v-if="!contact?.unknown")
      .img-box
        .h-full.w-full.grid.place-items-center.rounded-md(class="bg-[color:var(--brand-color-op-1)]" v-if="failedContactImg === true")
          p(class="bg-[linear-gradient(156.96deg,var(--brand-color)_9.13%,var(--brand-orange)_103.34%)] text-2xl bg-clip-text text-transparent") {{ contact?.name[0].toUpperCase() }}
        img(
          v-else-if="contact?.avatar && failedContactImg === false",
          style="border-radius: 4px",
          :src="contact?.avatarUrl",
          @error="failedContactImg = true"
        )
    .h-16.w-16.grid.place-items-center.rounded-md(class="bg-[color:var(--brand-color-op-1)]" v-else)
      p(class="bg-[linear-gradient(156.96deg,var(--brand-color)_9.13%,var(--brand-orange)_103.34%)] text-2xl bg-clip-text text-transparent") D
    .info-unknown-aligner(v-if="contact?.unknown")
      .name-unknown-contact-box Contato desconhecido
      .org-contact-box {{ _formatNumber({ number: contact?.number || phonenumber, country: "BR", }) }}
    .info-aligner(v-else="")
      .name-unknown-contact-box.max-w-xs.truncate(v-tooltip="contact.name") {{ contact.name }}
      .org-contact-box {{ contact.organization || 'Sem organização' }}
    .client-text(v-if="typeof tag === 'string' || tag?.name")
      | {{ typeof tag === 'string' ? tag : tag?.name }}
    .unknown-text.text-sm.whitespace-nowrap(class="xl:text-base" v-else="") Sem categoria
    span.edit-icon.material-icons.notranslate.text-white(
      @click="$emit('open-overlay', contact?.unknown ? 'add' : 'edit')"
    ) &#xe3c9
  .downside-contact-box
    v-button-secondary(v-on:click="$emit('open-callback')" class="!bg-opacity-0 !px-1 !py-1") Atribuir retorno
    v-button-secondary(class="!bg-opacity-0 !px-1 !py-1" @click="$store.commit('overlay/setActiveOverlay', { name: 'ContactHistory', context: contact.id ? {...contact} : {phonenumber: contact?.number || phonenumber} })") Ver histórico
    v-button-secondary(
      class="!bg-opacity-0 !px-1 !py-1"
      v-on:click="$store.commit('overlay/setActiveOverlay', { name: 'Callback', context: contact.number })"
    ) Atividades
.user-contact-box(v-else-if="contact.access_token")
  .upside-contact-box
    .img-box
      .h-full.w-full.grid.place-items-center.rounded-md(class="bg-[color:var(--brand-color-op-1)]" v-if="failedImg === true")
        p(class="bg-[linear-gradient(156.96deg,var(--brand-color)_9.13%,var(--brand-orange)_103.34%)] text-2xl bg-clip-text text-transparent") {{ contact?.name[0].toUpperCase() }}
      img(
        v-if="contact?.avatar && failedImg === false",
        style="border-radius: 4px",
        :src="contact?.avatar",
        @error="failedImg = true"
      )
    .info-aligner
      .name-unknown-contact-box.max-w-xs.truncate(v-tooltip="contact.name") {{ contact.name }}
      .org-contact-box
        | {{ contact?.organization || 'Sem organização' }}
    .client-text.Colega.colega-tag.p-1 Colega
  .downside-contact-box
    a.history-button(v-on:click="$emit('open-callback')") Atribuir retorno
    a.history-button(v-if="callbacks.length" v-on:click="$store.commit('overlay/setActiveOverlay', { name: 'Callback', context: contact.number })") Atividades
</template>

<script>
import { getAllContactCallbacks } from '@/services/cblist.js';
import VButtonSecondary from '@/components/Buttons/v-button-secondary.vue';
export default {
  components: {
    VButtonSecondary,
  },
  data() {
    return {
      failedImg: false,
      failedContactImg: false,
      callbacks: [],
    };
  },
  methods: {
    saveActualItem(itemId) {
      this.$store.commit('SET_ITEMID', itemId);
    },
    async getAllContactCallbacks() {
      try {
        this.callbacks = await getAllContactCallbacks({
          companyId: this.user.company_id,
          contactNumber: this.contact?.number,
          assignedTo: this.user.id,
          status: 101,
        });
      } catch (error) {
        this.$toast.open({
          message: 'Não foi possivel resgatar atividades atribuidas pendentes',
          type: 'warning',
        });
        this.callbacks = [];
      }
    },
  },
  watch: {
    async linkedid() {
      this.getAllContactCallbacks();
    },
    contact(value) {
      if (!value) return;
      this.getAllContactCallbacks();
    },
  },
  computed: {
    tag() {
      return this.$store.getters['rh/GET_TAG'];
    },
    phonenumber() {
      return this.$store.getters['rh/GET_PHONENUMBER'];
    },
    avatar() {
      return this.$store.getters['rh/GET_AVATAR'];
    },
    organization() {
      return this.$store.getters['rh/GET_CALLERNAME'];
    },
    linkedid() {
      return this.$route.params.callId;
    },
    contact() {
      return this.$store.getters['rh/GET_CONTACT'];
    },
  },
  created() {},
};
</script>

<style>
.edit-icon {
  background: #adb5bd;
  width: 24px;
  min-width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex !important;
  margin-left: 9%;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 16px !important;
  cursor: pointer;
}
.edit-icon:hover {
  background: #8f9295;
}
.user-contact-box {
  background: #e9ecef99;
  height: 169px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-width: 395px;
  padding-top: 21px;
  border-radius: 4px;
  align-items: center;
}
.mate-contact-box {
  background: #e9ecef99;
  height: 105px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-width: 395px;
  padding-top: 21px;
  border-radius: 4px;
  align-items: center;
}
.name-contact-box {
  font-family: 'Inter', sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  width: max-content;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 128.5px;
  color: #212529;
  margin-top: 2px;
}
.name-unknown-contact-box {
  font-family: 'Inter', sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  width: max-content;
  color: #212529;
  margin-top: 2px;
}
.org-contact-box {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin-top: 6.5px;
  line-height: 22px;
  color: #495057;
}
.img-box {
  height: 64px;
  min-width: 64px;
  width: 64px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-background {
  background: rgba(var(--brand-color), 0.2);
  color: white;
}
.info-aligner {
  margin-left: 10px;
  margin-right: auto;
  min-width: 35%;
}
.info-unknown-aligner {
  margin-left: 10px;
  margin-right: 20px;
  width: 58%;
  min-width: 100px;
}
.upside-contact-box {
  height: 84px;
  width: 90%;
  min-width: 355px;
  flex-direction: row;
  justify-content: flex-end;
  display: flex;
}
.downside-contact-box {
  height: 62px;
  width: 90%;
  min-width: 355px;
  flex-direction: row;
  border-top: 2px solid #dee2e6;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
}
</style>
