<template lang="pug">
div
  .h-screen.w-screen.absolute.left-0.top-0.z-50(v-if="isDragging || isResizing")
  .flex.rounded-xl.border.items-center.justify-center.bg-gray-100.gap-2.h-14.px-1.relative(
    v-if="isValidEvent && (currentDialState === dialState.IN_CALL || currentDialState === dialState.CALLING)"
  )
    f-tooltip
      .flex.relative.cursor-help
        .h-8.w-8.rounded-full.bg-slate-800.flex.items-center.justify-center.overflow-hidden(class="dark:invert")
          avatar(
            v-if="!identityContact.unknown"
            :src="identityContact.avatarUrl",
            :avatarId="identityContact.id",
          )
          img.h-full(
            v-else
            src="@/assets/images/default_pfp.png"
          )
        .material-icons.notranslate.absolute.-bottom-1.right-0.text-gray-600(
          class="!text-xs"
        ) info
      template(#popper)
        p.font-plus.text-white.font-bold Contato
        p.font-plus.text-white {{ identityContact?.name }}
        p.font-plus.text-white {{ callerNumber }}
    .flex.bg-white.border.rounded-xl.p-1.items-center.justify-center.gap-1(class="dark:invert")
      loading-ring(v-if="loadingHangUp")
      .flex.cursor-pointer.transition-all.rounded-md.items-center.justify-center.w-8.h-8(
        class="hover:bg-gray-100",
        v-tooltip="'Ativar/Desativar microfone'"
        :selected="muted"
        :class="{'opacity-60 pointer-events-none' : isTransferring}"
        @click="checkMute"
        v-if="isWebRTC && !loadingHangUp && currentDialState !== dialState.CALLING"
      )
        .material-icons.notranslate.text-xl.text-gray-600 {{ muted ? 'mic_off' : 'mic' }}
      .flex.cursor-pointer.transition-all.rounded-md.items-center.justify-center.w-8.h-8(
        class="hover:bg-gray-100",
        v-tooltip="'Desligar chamada'"
        @click="hangUp"
        v-if="isWebRTC && !loadingHangUp"
      )
        .material-icons.notranslate.text-xl.text-red-500 call_end
      .relative(v-if="!loadingHangUp")
        .flex.cursor-pointer.transition-all.rounded-md.items-center.justify-center.w-8.h-8(
          class="hover:bg-gray-100",
          :class="{'bg-gray-100' : isDialerOpen}"
          v-tooltip="'Abrir chamada'"
          @click="handleOpen"
        )
          .material-icons.notranslate.text-xl.text-gray-600 {{ isDialerOpen ? 'zoom_in_map' : 'zoom_out_map' }}
        .absolute.mt-2.bg-white.rounded-lg.shadow-lg.z-50(
          v-show="isDialerOpen",
          :style="{top: dialerPosition.y + 'px', left: dialerPosition.x + 'px', width: dialerSize.width + 'px', height: dialerSize.height + 'px'}"
        )
          calling(
            v-if="isValidEvent && currentDialState === dialState.CALLING",
            :data="socketMessage",
            :extra="extraCall",
            @hangup-call="currentDialState = dialState.DEFAULT"
          )
          in-call(
            v-if="isValidEvent && currentDialState === dialState.IN_CALL",
            :data="socketMessage",
            :extra="extraCall",
            @hangup-call="currentDialState = dialState.DEFAULT"
          )
    .absolute.w-fit.h-fit.z-10.-top-1.-right-0.rounded-full.items-center.justify-center.bg-brand.px-1(
      v-if="isUpdating",
      class="dark:invert"
    )
      .material-icons.notranslate.text-xs.text-white.z-20 autorenew
    .flex(v-else)
      .absolute.w-3.h-3.z-10.-top-1.-right-0.rounded-full(
        :class="statusBallColor",
        class="dark:invert"
      )
      .absolute.w-3.h-3.z-10.-top-1.-right-0.rounded-full.animate-ping.cursor-pointer(
        v-if="!isRegistered",
        v-tooltip="'Dispositivo desconectado, clique para tentar reconectar'",
        :class="statusBallColor",
        class="dark:invert",
        @click="synchronize"
      )
  .relative(v-else)
    .flex.relative
      .flex.rounded-xl.border.items-center.justify-center.w-11.h-11.transition-all.cursor-pointer(
        class="hover:bg-gray-100 active:scale-90",
        :class="{'bg-red-100' : isValidEvent && currentDialState === dialState.INCOMING_CALL}",
        @click="handleOpen"
      )
        .material-icons.text-gray-500(style="font-size: 18px") phone
      .flex.rounded-xl.border.items-center.justify-center.w-11.h-11.transition-all.cursor-pointer.absolute.animate-ping.bg-red-100(v-if="isValidEvent && currentDialState === dialState.INCOMING_CALL")
      .absolute.w-fit.h-fit.z-10.-top-1.-right-0.rounded-full.items-center.justify-center.bg-brand.px-1(
        v-if="isUpdating",
        class="dark:invert"
      )
        .material-icons.notranslate.text-xs.text-white.z-20 autorenew
      .flex(v-else)
        .absolute.w-3.h-3.z-10.-top-1.-right-0.rounded-full(
          :class="statusBallColor",
          class="dark:invert"
        )
        .absolute.w-3.h-3.z-10.-top-1.-right-0.rounded-full.animate-ping.cursor-pointer(
          v-if="!isRegistered",
          v-tooltip="'Dispositivo desconectado, clique para tentar reconectar'",
          :class="statusBallColor",
          class="dark:invert",
          @click="synchronize"
        )
    .absolute.mt-2.bg-white.rounded-lg.shadow-lg.z-50(
      v-show="isDialerOpen",
      :style="{top: dialerPosition.y + 'px', left: dialerPosition.x + 'px', width: dialerSize.width + 'px', height: dialerSize.height + 'px'}"
      :class="{'w-96 h-44' : currentDialState === dialState.INCOMING_CALL}"
    )
      incoming-call(
        v-if="isValidEvent && currentDialState === dialState.INCOMING_CALL",
        :data="socketMessage",
        :extra="extraCall",
        @hangup-call="currentDialState = dialState.DEFAULT"
      )
      dialer-home(v-else)
</template>
<script>
import useGetDeviceRegistryMixin from '@/mixins/cstate/use-get-device-registry';
import IncomingCall from '@/components/Dialer/dialer-incoming-call.vue';
import DialerLayout from '@/components/Dialer/dialer-layout.vue';
import DialerOption from '@/components/Dialer/dialer-option.vue';
import UserOrContactMixin from '@/mixins/user-or-contact.mixin';
import DialerHome from '@/components/Dialer/dialer-home.vue';
import InCall from '@/components/Dialer/dialer-in-call.vue';
import Calling from '@/components/Dialer/dialer-calling';
import LoadingRing from '@/components/LoadingRing.vue';
import Avatar from '@/components/Avatars/avatar.vue';
import SipMixin from '@/mixins/invite-calls.js';
import cservice from '@/services/cservice';
import cstate from '@/services/cstate';
import { Tooltip } from 'floating-vue';

export default {
  mixins: [useGetDeviceRegistryMixin, UserOrContactMixin, SipMixin],
  components: {
    FTooltip: Tooltip,
    IncomingCall,
    DialerLayout,
    DialerOption,
    LoadingRing,
    DialerHome,
    Calling,
    Avatar,
    InCall,
  },
  data() {
    return {
      mute: 'off',
      loadingHangUp: false,
      isUpdating: false,
      dialState: {
        DEFAULT: 0,
        INCOMING_CALL: 1,
        CALLING: 2,
        IN_CALL: 4,
        CALL_ENDED: 5,
      },
    };
  },
  watch: {
    currentDialState(val) {
      this.$store.commit('sip/setTransferring', false);
      this.$store.commit('sip/setMuted', false);
      if (this.activeSider) {
        this.$store.commit('sip/setSize', {
          width: this.dialerSize.width - 270,
          height: this.dialerSize.height,
        });
        this.$store.commit('sip/setActiveSider', false);
      }
      if (val === this.dialState.DEFAULT) return this.handleHide();
      this.handleShow();
    },
    async callerNumber(val) {
      this.setContactOrOperator({
        id: this.callerId,
        number: val,
      });
    },
  },
  computed: {
    isResizing() {
      return this.$store.getters['sip/isResizing'];
    },
    isDragging() {
      return this.$store.getters['sip/isDragging'];
    },
    activeSider() {
      return this.$store.getters['sip/activeSider'];
    },
    dialerPosition() {
      return this.$store.getters['sip/position'];
    },
    dialerSize() {
      return this.$store.getters['sip/size'];
    },
    isRegistered() {
      return this.$store.getters['cstate/IS_DEVICE_CONNECTED'];
    },
    statusBallColor() {
      if (
        this.currentDialState == this.dialState.INCOMING_CALL ||
        this.currentDialState == this.dialState.CALLING ||
        this.currentDialState == this.dialState.IN_CALL ||
        !this.isRegistered
      )
        return 'bg-rose-500';
      return 'bg-emerald-400';
    },
    call() {
      return this.$store.getters['cstate/GET_CALL_PAYLOAD'];
    },
    fromId() {
      return this.call?.fromId || this.call?.from_id;
    },
    isMineCall() {
      return this.fromId === this.user.id || this.call?.from === this.user.endpoint;
    },
    callerId() {
      return this.isMineCall ? this.call?.toId : this.fromId;
    },
    callerNumber() {
      return this.isMineCall ? this.call?.to : this.call?.from;
    },
    isDialerOpen() {
      return this.$store.getters['dialer/getIsDialerOpen'];
    },
    currentDialState: {
      set(newstate) {
        this.$store.commit('cstate/SET_DIALER_STATE', newstate);
      },
      get() {
        return this.$store.getters['cstate/GET_DIALER_STATE'];
      },
    },
    webphone() {
      return this.$store.getters['sip/agent'];
    },
    session() {
      return this.$store.getters['sip/session'];
    },
    activeInterface() {
      if (!this.webphone) return true;
      return !!this.session.session;
    },
    extraCall() {
      return this.$store.getters['cstate/GET_EXTRA_CALL'];
    },
    socketMessage() {
      return this.$store.getters['cstate/GET_CALL_PAYLOAD'];
    },
    isValidEvent() {
      if (this.isWebRTC) {
        return this.session.session && this.activeInterface;
      } else return true;
    },
    session() {
      return this.$store.getters['sip/session'];
    },
    muted() {
      return this.$store.getters['sip/muted'];
    },
    isTransferring() {
      return this.$store.getters['sip/transferring'];
    },
    cstateSocket() {
      return this.$store.getters['cstate/GET_SOCKET'];
    },
  },
  methods: {
    async resetWebphone() {
      if (!this.isWebRTC) return;
      this.$store.commit('sip/destroyUserAgent');
      this.$store.dispatch('sip/createUserAgent', {
        username: this.user.username,
        endpoint: this.user.endpoint,
      });
    },
    async synchronize() {
      if (this.isUpdating) return;
      this.isUpdating = true;
      try {
        await this.resetWebphone();
        await this.getDeviceRegistry(this.user.endpoint);
      } catch (error) {
        console.error(error);
        this.$toast.error('Falha ao buscar registro do ramal...');
      } finally {
        this.isUpdating = false;
      }
    },
    async hangUp() {
      if (this.loadingHangUp) return;
      this.loadingHangUp = true;
      try {
        await this._hangUpCall();
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingHangUp = false;
      }
    },
    handleOpen() {
      if (this.isDialerOpen) return this.handleHide();
      this.handleShow();
    },
    handleShow() {
      this.$store.commit('dialer/setIsDialerOpen', true);
    },
    handleHide() {
      this.$store.commit('dialer/setIsDialerOpen', false);
    },
    async checkMute() {
      if (this.mute == 'off') {
        this.mute = 'on';
        this.$store.commit('sip/setMuted', true);
      } else {
        this.mute = 'off';
        this.$store.commit('sip/setMuted', false);
      }
      try {
        if (this.webphone && this.session) return this.muteSession(this.muted);
        await this.muteFunction(this.mute);
      } catch (error) {
        console.error(error);
        if (this.muted) return this.$toast.error('Não foi possível ativar o microfone');
        this.$toast.error('Não foi possível desativar o microfone');
      }
    },
    handlePeerStatusUpdate(endpoint, data) {
      this.$store.commit('sip/updateMateStatus', { endpoint, status: data.online ? 'online' : 'offline' });
    },
    handleUserUpdate(endpoint, data) {
      let status = 'online';
      if (data.oncall) status = 'oncall';
      if (data.pause) status = 'pause';
      this.$store.commit('sip/updateMateStatus', { endpoint, status });
    },
    async getAllMates() {
      this.$store.commit('sip/setLoadingMates', true);
      try {
        const mates = await cservice.getAllUsers({ status: 'activated', withoutOpensUsers: true });
        for (const mate of mates) {
          const mateStatus = await cstate.isDeviceConnected(mate.endpoint);
          let status = 'offline';
          if (mateStatus == 'true') status = 'online';
          if (mate.oncall && mateStatus == 'true') status = 'oncall';
          if (mate.pause && mateStatus == 'true') status = 'pause';
          mate.status = status;
          this.cstateSocket.on(`peerStatus-${mate.endpoint}`, this.handlePeerStatusUpdate.bind(this, mate.endpoint));
          this.cstateSocket.on(`status-agent-${mate.id}`, this.handleUserUpdate.bind(this, mate.endpoint));
        }
        this.$store.commit('sip/setMatesList', mates);
      } catch (error) {
        console.error(error);
        this.$toast.error('Não foi possível carregar os colegas');
      } finally {
        this.$store.commit('sip/setLoadingMates', false);
      }
    },
  },
  async mounted() {
    await this.getAllMates();
  },
  beforeDestroy() {
    const mates = this.$store.getters['sip/matesList'];
    mates.forEach((mate) => {
      this.cstateSocket.off(`peerStatus-${mate.endpoint}`);
      this.cstateSocket.off(`status-agent-${mate.id}`);
    });
  },
};
</script>
