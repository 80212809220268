<template lang="pug">
.simple-input(:class="[inputTheme]")
  .flex.h-full.pl-2
    span.material-icons.notranslate.my-auto.text-gray-400 {{ icon }}
    p.text-gray-500.font-plus.font-medium.p-1.px-2.transition(
      :class="{'translate-y-0 text-sm': upPlaceholder(), '-translate-y-1/3 text-xs': !upPlaceholder()}"
    ) {{ label }}
  input.pl-8.absolute.w-full.h-full.outline-none.bg-transparent.appearance-none.indent-2.pt-3.font-plus.font-semibold(
    v-model="content",
    @keyup.enter="$emit('keyup-enter')"
    @input="handleInput",
    @focus="focus = true"
    @blur="focus = false"
    :class="[disabledStatus, textTheme]"
    :type="replaceType || type",
    :placeholder="placeholder",
    :disabled="disabled"
    :MAXLENGTH="caract"
  )
</template>

<script>
export default {
  props: {
    value: String,
    label: String,
    placeholder: {
      type: String,
      default: '',
    },
    type: String,
    tooltip: Object,
    caract: {
      type: Number,
      default: 1000,
    },
    icon: {
      type: String,
      default: 'search',
    },
    isPhoneNumber: {
      type: Boolean,
      default: false,
    },
    isCnpj: {
      type: Boolean,
      default: false,
    },
    isCep: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      contentSet: false,
      content: '',
      visibility: false,
      replaceType: this.type,
      focus: false,
    };
  },
  computed: {
    textTheme() {
      if (this.dark) return 'text-white';
      return 'text-gray-800';
    },
    inputTheme() {
      if (this.dark) return '!bg-gray-800';
      return '!bg-gray-100';
    },
    disabledStatus() {
      if (this.disabled) return 'opacity-60 cursor-not-allowed';
      return '';
    },
  },
  methods: {
    upPlaceholder() {
      if (this.focus) return false;
      if (this.content) return false;
      return true;
    },
    handleInput(e) {
      if (this.isPhoneNumber) {
        this.content = this.formatPhoneNumber(e.target.value);
      } else if (this.isCnpj) {
        this.content = this.formatCnpj(e.target.value);
      } else if (this.isCep) {
        this.content = this.formatCep(e.target.value);
      } else {
        this.content = e.target.value;
      }
      this.$emit('input', this.content);
    },
    generatePassword() {
      this.content = Math.random().toString(36).slice(2);
      this.$emit('input', this.content);
    },
    formatPhoneNumber(phoneNumber) {
      phoneNumber = phoneNumber.replace(/\D/g, '');
      let formattedPhoneNumber = '';

      if (phoneNumber.length > 0) {
        formattedPhoneNumber += '(' + phoneNumber.substring(0, 2);
      }
      if (phoneNumber.length > 2) {
        formattedPhoneNumber += ') ' + phoneNumber.substring(2, 7);
      }
      if (phoneNumber.length > 7) {
        formattedPhoneNumber += '-' + phoneNumber.substring(7, 11);
      }

      return formattedPhoneNumber;
    },
    formatCnpj(cnpj) {
      cnpj = cnpj.replace(/\D/g, '');
      let formattedCnpj = '';

      if (cnpj.length > 0) {
        formattedCnpj += cnpj.substring(0, 2);
      }
      if (cnpj.length > 2) {
        formattedCnpj += '.' + cnpj.substring(2, 5);
      }
      if (cnpj.length > 5) {
        formattedCnpj += '.' + cnpj.substring(5, 8);
      }
      if (cnpj.length > 8) {
        formattedCnpj += '/' + cnpj.substring(8, 12);
      }
      if (cnpj.length > 12) {
        formattedCnpj += '-' + cnpj.substring(12, 14);
      }
      return formattedCnpj;
    },
    formatCep(cep) {
      cep = cep.replace(/\D/g, '');
      let formattedCep = '';
      if (cep.length > 0) {
        formattedCep += cep.substring(0, 5);
      }
      if (cep.length > 5) {
        formattedCep += '-' + cep.substring(5, 8);
      }
      return formattedCep;
    },
  },
  watch: {
    focus(value) {
      if (!value) {
        this.$emit('blur');
        return;
      }
      this.$emit('focus');
    },
    value(n) {
      if (this.contentSet) return;
      this.content = n;
      this.contentSet = true;
    },
  },
  created() {
    this.content = this.value;
  },
};
</script>
<style lang="scss">
input:invalid {
  border-bottom: 2px solid red !important;
}
</style>
