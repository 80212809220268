<template lang="pug">
.w-full.h-12
  .flex.gap-5.items-center
    span.material-icons.notranslate.w-6.h-6.rounded-full.grid.place-items-center.bg-emerald-400.text-white.text-xs call_received
    span.font-normal.text-base.text-zinc-600.font-inter
      | Atendimento atribuído a
      b  {{ operator?.username }}
    span.ml-auto.font-normal.text-sm.font-inter(
      class="!text-[#868e96]"
      v-tooltip="formatTimestamp(data.createdAt)"
    ) {{formatTimeDiff(data.createdAt, calldate)}}
</template>

<script>
import { dateFormatMixin } from '@/mixins/date-format.mixin';
import cservice from '@/services/cservice';

export default {
  name: 'attendingassigned',
  mixins: [dateFormatMixin],
  props: ['data', 'calldate'],
  data() {
    return {
      operator: {},
    };
  },
  methods: {
    async getUser() {
      try {
        const data = await cservice.getUserById(this.data.destination);
        this.operator = data;
      } catch (error) {
        this.clearData();
      }
    },
    clearData() {
      this.operator = {};
    },
  },
  created() {
    this.getUser();
  },
};
</script>

