<template lang="pug">
.flex.bg-neutral-50.rounded-tl-md.items-start.justify-start.flex-col.mt-8.mx-8
  p.font-titillium.text-zinc-700.font-extrabold.text-3xl Casos
  .subtitle-Text Lista de casos do seu interesse
  .header
    IconInput(icon="search", v-model="title", label="Caso")
    .button-container
      v-button(
        @click="$store.commit('overlay/setActiveOverlay', { name: 'CreateCase' })"
      ) Criar caso
  .filters-container.flex.space-evenly.w-full.items-end
    dropdown-clients(
      :model="client",
      @value="setValue",
      :attr="'name'",
      :displayAttr="'name'",
      :jsonData="clientsList",
      :useId="true",
      :dataFilter="'name'",
      :placeholder="'Selecione um cliente'",
      @remove-filter="resetClient",
      @intersect="intersected"
    )
      //- contact-dropdown-input(
    //-   :model="responsible",
    //-   :attr="'assignedTo'",
    //-   :displayAttr="'name'",
    //-   :jsonData="responsibleList",
    //-   :useId="true",
    //-   :dataFilter="'username'",
    //-   @remove-filter="resetResponsible",
    //-   :placeholder="'Selecione um agente ou grupo responsável'"
    //- )
    DropdownTabInput(
      :model="responsible",
      :attr="'assignedTo'",
      @data-selected="setResponsible",
      :displayAttr="'name'",
      :jsonData="responsibleList",
      :useId="true",
      :dataFilter="'name'",
      @remove-filter="resetResponsible",
      :placeholder="'Selecione um responsável'"
    )
    PeriodFilter(@filter="filterPeriod")
    StatusFilter(:options="statusTypes", @filter="filterStatus")
  CasesTable(:cases="filteredCases", v-if="loaded")
</template>

<script>
import PeriodFilter from '@/pages/Callbacks/Callbacks.History.PeriodFilter.vue';
import StatusFilter from '@/pages/Cases/Cases.StatusFilter.vue';
import CasesTable from '@/pages/Cases/Cases.CasesTable.vue';
import ContactDropdownInput from '@/pages/Contacts/Contact.DropdownInput.vue';
import DropdownClients from '@/components/Dropdown/dropdown-clients.vue';
import VButton from '@/components/Buttons/v-button.vue';
import DropdownTabInput from '@/components/Dropdown/dropdown-tab-input.vue';
import IconInput from '@/components/Inputs/IconInput.vue';
import { debounce } from '@/helpers';
import contactList from '@/services/contact-list';
import CService from '@/services/cservice';

export default {
  title: 'Opens - Casos',
  components: {
    PeriodFilter,
    StatusFilter,
    CasesTable,
    ContactDropdownInput,
    DropdownClients,
    VButton,
    IconInput,
    DropdownTabInput,
  },
  data() {
    return {
      casesWhiteListedCompanies: process.env.VUE_APP_CASES_WHITE_LIST,
      title: '',
      statusTypes: [
        { name: 'Aberto', id: 3 },
        { name: 'Em andamento', id: 4 },
        { name: 'Fechado', id: 5 },
      ],
      clientsList: [],
      companyUserList: [],
      responsibleList: {
        agents: [],
        groups: [],
      },
      page: 1,
      oldName: '',
      loaded: false,
      statusModel: {
        id: null,
      },
      periodFilterValue: {
        start: new Date('2000-05-06T17:05:47.796Z'),
        end: new Date('3000-05-06T17:05:47.796Z'),
      },
      responsible: {
        assignedTo: '',
        name: '',
      },
      client: {
        name: '',
        id: '',
      },
      contactSearchValue: '',
    };
  },
  computed: {
    workGroups() {
      return this.$store.getters['performance/WORK_GROUPS'];
    },
    casesList() {
      let casosWithResponsibles = this.addResponsiblesToCases(this.$store.getters['GET_CASES']);
      let casos = this.addClientsToCases(casosWithResponsibles);
      return casos;
    },
    filteredCases() {
      let obj = {
        result: this.casesList,
        filterByClient(client) {
          this.result = this.result.filter((caso) => caso.customerId.includes(client.id));
          return this;
        },
        filterByResponsible(assignedTo) {
          this.result = this.result.filter((caso) => caso.assignedTo.includes(assignedTo));
          return this;
        },
        filterByStatus(statusModel) {
          this.result = this.result.filter((caso) => {
            if (statusModel.id) {
              return statusModel.id == caso.caseStatusId;
            }
            return caso.caseStatusId != 5;
          });
          return this;
        },
        filterByDate(periodFilterValue) {
          this.result = this.result.filter(
            (caso) =>
              new Date(caso.createdAt) > periodFilterValue.start && new Date(caso.createdAt) < periodFilterValue.end,
          );
          return this;
        },
        filterByTitle(title) {
          this.result = this.result.filter((caso) => caso.title.toLowerCase().includes(title.toLowerCase()));
          return this;
        },
        run() {
          return this.result;
        },
      };

      return obj
        .filterByClient(this.client)
        .filterByResponsible(this.responsible.assignedTo)
        .filterByStatus(this.statusModel)
        .filterByDate(this.periodFilterValue)
        .filterByTitle(this.title)
        .run();
    },
  },
  watch: {
    contactSearchValue(val) {
      this.searchTable();
    },
  },
  methods: {
    setValue(val) {
      this.contactSearchValue = val;
    },
    setResponsible(val) {
      this.responsible = { name: val.username, assignedTo: val.id };
    },
    async intersected() {
      const { getAllContactsQuery } = contactList;
      const data = await getAllContactsQuery({
        offset: this.page * 20,
        limit: this.page * 20 + 20,
      });

      this.page++;
      this.clientsList = [...this.clientsList, ...data];
    },
    searchTable: debounce(async function () {
      if (this.oldName != this.contactSearchValue) {
        this.page = 1;
      }
      const page = this.page;

      if (!this.contactSearchValue) {
        this.clientsList = await contactList.getAllContactsQuery({
          offset: 0,
          limit: 20,
        });
        return;
      }
      this.clientsList = await contactList.getContactsByName({
        offset: (page - 1) * 20,
        limit: (page - 1) * 20 + 20,
        name: this.contactSearchValue.toLowerCase(),
      });
      this.oldName = this.contactSearchValue.toLowerCase();
    }, 500),
    filterPeriod(data) {
      this.periodFilterValue = data;
    },
    filterStatus(data) {
      this.statusModel = data;
    },
    resetClient() {
      this.client = {
        name: '',
        id: '',
      };
    },
    resetResponsible() {
      this.responsible = {
        user: '',
        assignedTo: '',
      };
    },
    addClientsToCases(casos) {
      casos.forEach((element) => {
        const client = this.clientsList.filter((client) => {
          return element.customerId == client.id;
        })[0];

        element.client = client;
      });
      return casos;
    },
    addResponsiblesToCases(casos) {
      casos.forEach((element) => {
        let responsible = this.companyUserList.filter((responsible) => {
          return element.assignedTo == responsible.id;
        })[0];
        const group = this.workGroups.filter((responsible) => {
          return element.assignedTo == responsible.id;
        })[0];
        if (group) {
          responsible = group;
          responsible.isGroup = true;
        }

        if (!responsible) responsible = null;

        element.responsible = responsible;
      });
      return casos;
    },
  },
  async created() {
    if (this.casesWhiteListedCompanies && !this.casesWhiteListedCompanies?.split(',').includes(this.user.company_id))
      return this.$router.push({ name: 'Principal' });
    const { getAllContactsQuery } = contactList;
    try {
      await this.$store.dispatch('updateCases', this.$http);
      this.companyUserList = await CService.getAllUsers({ status: 'activated', withoutOpensUsers: true });
      this.clientsList = await getAllContactsQuery({ offset: 0, limit: 20 });
      this.loaded = true;
      this.responsibleList.agents = this.companyUserList;
      this.responsibleList.groups = this.workGroups;
    } catch (error) {
      if (error.response?.status == 401) {
        window.location.pathname = '/auth/login';
      }
    }
  },
};
</script>

<style scoped lang="scss">
.filters-container {
  margin-top: 1rem;
  margin-bottom: 1rem;
  gap: 0.5rem;
}
.header {
  margin-top: 2.4rem;
}
.filter-input {
  text-indent: 3px;
}
</style>
