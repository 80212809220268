<template lang="pug">
.h-fit.flex.p-2.items-start.justify-start
  .flex.gap-1.items-start.flex-col.w-full.h-full.justify-start
    p.font-plus.text-xs.text-gray-500 Categorias:
    .flex
      p.text-2xl.rounded-md.font-noto-emoji(
        class="hover:bg-slate-200 transition-all cursor-pointer active:scale-90 dark:invert"
        :class="{'bg-brand-150' : selectedEmojiCategory == 'smileys_emotion'}"
        v-tooltip="'Sorrisos'"
        @click="selectedEmojiCategory = 'smileys_emotion'"
      ) 😀
      p.text-2xl.rounded-md(
        class="hover:bg-slate-200 transition-all cursor-pointer active:scale-90 dark:invert"
        :class="{'bg-brand-150' : selectedEmojiCategory == 'people_body'}"
        v-tooltip="'Pessoas'"
        @click="selectedEmojiCategory = 'people_body'"
      ) 🦰
      p.text-2xl.rounded-md.font-noto-emoji(
        class="hover:bg-slate-200 transition-all cursor-pointer active:scale-90 dark:invert"
        :class="{'bg-brand-150' : selectedEmojiCategory == 'animals_nature'}"
        v-tooltip="'Animais e natureza'"
        @click="selectedEmojiCategory = 'animals_nature'"
      ) 🐶
      p.text-2xl.rounded-md.font-noto-emoji(
        class="hover:bg-slate-200 transition-all cursor-pointer active:scale-90 dark:invert"
        :class="{'bg-brand-150' : selectedEmojiCategory == 'food_drink'}"
        v-tooltip="'Comidas e bebidas'"
        @click="selectedEmojiCategory = 'food_drink'"
      ) ☕
      p.text-2xl.rounded-md.font-noto-emoji(
        class="hover:bg-slate-200 transition-all cursor-pointer active:scale-90 dark:invert"
        :class="{'bg-brand-150' : selectedEmojiCategory == 'travel_places'}"
        v-tooltip="'Viagem e lugares'"
        @click="selectedEmojiCategory = 'travel_places'"
      ) ✈️
      p.text-2xl.rounded-md.font-noto-emoji(
        class="hover:bg-slate-200 transition-all cursor-pointer active:scale-90 dark:invert"
        :class="{'bg-brand-150' : selectedEmojiCategory == 'activities'}"
        v-tooltip="'Atividades e esportes'"
        @click="selectedEmojiCategory = 'activities'"
      ) ⚽
      p.text-2xl.rounded-md.font-noto-emoji(
        class="hover:bg-slate-200 transition-all cursor-pointer active:scale-90 dark:invert"
        :class="{'bg-brand-150' : selectedEmojiCategory == 'objects'}"
        v-tooltip="'Objetos'"
        @click="selectedEmojiCategory = 'objects'"
      ) 💡
      p.text-2xl.rounded-md.font-noto-emoji(
        class="hover:bg-slate-200 transition-all cursor-pointer active:scale-90 dark:invert"
        :class="{'bg-brand-150' : selectedEmojiCategory == 'symbols'}"
        v-tooltip="'Símbolos'"
        @click="selectedEmojiCategory = 'symbols'"
      ) 🚸
      p.text-2xl.rounded-md.font-noto-emoji(
        class="hover:bg-slate-200 transition-all cursor-pointer active:scale-90 dark:invert"
        :class="{'!bg-brand-150' : selectedEmojiCategory == 'flags'}"
        v-tooltip="'Bandeiras'"
        @click="selectedEmojiCategory = 'flags'"
      ) 🏳️
    .w-full.bg-gray-300(class="h-[1px]")
    .flex.flex-wrap.overflow-y-scroll.gap-1.items-start.justify-start.content-start.max-w-xs.max-h-80
      p.text-2xl.rounded-md.font-noto-emoji(
        class="hover:bg-slate-200 transition-all cursor-pointer active:scale-90 dark:invert"
        v-for="emoji in emojiList"
        :key="emoji.slug"
        @click="$emit('emojiSelected', emoji.emoji)"
      ) {{ emoji.emoji }}
</template>

<script>
import emojiData from 'unicode-emoji-json/data-by-group.json';

export default {
  data() {
    return {
      selectedEmojiCategory: 'smileys_emotion',
    };
  },
  computed: {
    emojiList() {
      return emojiData.filter((emoji) => emoji.slug == this.selectedEmojiCategory)[0].emojis;
    },
  },
};
</script>
